import React, { useState, useEffect } from "react";
import {
  Card,
  CardContent,
  Typography,
  Button,
  Grid,
  Container,
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@mui/material";
import { NavLink, useNavigate } from "react-router-dom";
import { useSignedInUserState } from "../hooks/useAuthState";
import { restaurantService } from "../services/firebase/restaurantManagementService";
import { StringsService } from "../services/strings/strings";
import { FullProperty } from "../models/fullProperty";
import AsyncCardMedia from "../shared/asyncCardMedia";
import { NavButton } from "../shared/navButton";
import AsyncNavButton from "../shared/asyncNavButton";

const RestaurantGlobalManagementPage: React.FC = () => {
  const [restaurants, setRestaurants] = useState<FullProperty[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<string>("");
  const [confirmDialog, setConfirmDialog] = useState<{ open: boolean; restaurantId?: string }>({
    open: false,
  });
  const user = useSignedInUserState().user;
  const navigate = useNavigate();

  useEffect(() => {
    const fetchRestaurants = async () => {
      try {
        if (!user || !user.uid) {
          setError(await StringsService.getStringById("error-not-signed-in"));
          setLoading(false);
          return;
        }
        setLoading(true);
        const data = await restaurantService.fetchRestaurantsByOwner(user?.uid);
        setRestaurants(data);
      } catch (err) {
        setError("Failed to fetch restaurants. Please try again later.");
      } finally {
        setLoading(false);
      }
    };

    fetchRestaurants();
  }, [user?.uid]);

  const handleAddRestaurant = () => {
    navigate("/restaurantManagement/new"); // Replace with your actual route for adding a restaurant
  };

  const handleSetInactive = async () => {
    const { restaurantId } = confirmDialog;
    if (!restaurantId) return;

    try {
      await restaurantService.changeRestaurantActivityStatus(restaurantId, false); // Mark as inactive
      setRestaurants((prev) =>
        prev.map((restaurant) =>
          restaurant.id === restaurantId ? { ...restaurant, isActive: false } : restaurant
        )
      );
    } catch (error) {
      setError("Failed to mark restaurant as inactive. Please try again later.");
    } finally {
      setConfirmDialog({ open: false });
    }
  };

  const handleSetActive = async (restaurantId: string) => {
    try {
      await restaurantService.changeRestaurantActivityStatus(restaurantId, true); // Mark as active
      setRestaurants((prev) =>
        prev.map((restaurant) =>
          restaurant.id === restaurantId ? { ...restaurant, isActive: true } : restaurant
        )
      );
    } catch (error) {
      setError("Failed to mark restaurant as active. Please try again later.");
    }
  };

  return (
    <Box
      sx={{
        backgroundColor: "#f5f5f5",
        minHeight: "100vh",
        display: "flex",
        alignItems: "top",
        justifyContent: "center",
      }}
    >
      <Container>
        <Box textAlign="center" mt={4} mb={4}>
          <Typography variant="h4">My Restaurants</Typography>
        </Box>
        <Button
          variant="contained"
          color="primary"
          onClick={handleAddRestaurant}
          sx={{
            marginTop: 2,
            height: "50px",
            backgroundColor: "#FFD700",
            color: "black",
            "&:hover": { backgroundColor: "#FFC300" },
          }}
        >
          Add New Restaurant
        </Button>
        <div style={{ padding: "16px" }} />
        <Grid container spacing={3}>
          {restaurants.map((restaurant) => (
            <Grid item xs={12} sm={6} md={4} key={restaurant.id}>
              <Card sx={{ maxWidth: 345, margin: "auto" }} 
              onClick={() => {navigate(`/restaurantManagement/edit/${restaurant.id}`)}}>
                <AsyncCardMedia
                  component="img"
                  height="140"
                  imageId={restaurant.id + "-thumbnail"}
                  alt="Placeholder Alt Image"
                />
                <CardContent>
                  <Typography variant="h6" component="div" gutterBottom>
                    {restaurant.name}
                  </Typography>
                  <Typography variant="body2" color="text.secondary">
                    {restaurant.isActive ? "Active" : "Inactive"}
                  </Typography>
                </CardContent>
                <Box textAlign="center" pb={2}>
                  {/* {restaurant.isActive ? (
                    <Button
                      variant="outlined"
                      color="secondary"
                      onClick={() => setConfirmDialog({ open: true, restaurantId: restaurant.id })}
                    >
                      Mark as Inactive
                    </Button>
                  ) : (
                    <Button
                      variant="outlined"
                      color="primary"
                      onClick={() => handleSetActive(restaurant.id)}
                    >
                      Mark as Active
                    </Button>
                  )} */}
                </Box>
              </Card>
            </Grid>
          ))}
        </Grid>
        {/* <Dialog
          open={confirmDialog.open}
          onClose={() => setConfirmDialog({ open: false })}
        >
          <DialogTitle>Confirm Inactivation</DialogTitle>
          <DialogContent>
            <DialogContentText>
              Are you sure you want to mark this restaurant as inactive? This action can be undone by marking it active later.
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => setConfirmDialog({ open: false })} color="primary">
              Cancel
            </Button>
            <Button onClick={handleSetInactive} color="secondary" autoFocus>
              Mark as Inactive
            </Button>
          </DialogActions>
        </Dialog> */}
      </Container>
    </Box>
  );
};

export default RestaurantGlobalManagementPage;
