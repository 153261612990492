import React, { useState } from "react";
import {
    signInWithEmailAndPassword,
    setPersistence,
    browserLocalPersistence,
    browserSessionPersistence,
    GoogleAuthProvider,
    OAuthProvider,
    signInWithPopup,
} from "firebase/auth";
import { auth } from "../firebase";
import { NavLink, useNavigate } from "react-router-dom";
import {
    Container,
    Box,
    Typography,
    TextField,
    Button,
    Checkbox,
    FormControlLabel,
    Link,
    CircularProgress,
    Dialog,
    DialogContent,
    DialogTitle,
    DialogActions,
    Stack
} from "@mui/material";
import "./auth.css";
import { StringsService } from "../services/strings/strings";
import GoogleIcon from "@mui/icons-material/Google"; // For Google Button
import AppleIcon from "@mui/icons-material/Apple";   // For Apple Button
import AsyncString from "../shared/asyncString";

export const LoginPage = () => {
    const navigate = useNavigate();
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState<string | null>(null);

    const onLogin = async (e: React.FormEvent) => {
        e.preventDefault();
        if (loading) {
            return;
        }
        setLoading(true); // Show loading spinner
        setError(null); // Clear previous errors

        try {
            await setPersistence(auth, browserLocalPersistence); // Persist login state
            const userCredential = await signInWithEmailAndPassword(auth, email, password);
            const user = userCredential.user;
            console.log(user);
            navigate("/search");
        } catch (error: any) {
            // Handle specific Firebase error codes
            if (error.code === "auth/wrong-password") {
                setError(await StringsService.getStringById("error-wrong-password"));
            } else if (error.code === "auth/user-not-found") {
                setError(await StringsService.getStringById("error-user-not-found"));
            } else if (error.code === "auth/network-request-failed") {
                setError(await StringsService.getStringById("error-network"));
            } else {
                setError(await StringsService.getStringById("error-unknown"));
            }
            console.error(error.code, error.message);
        } finally {
            setLoading(false); // Hide loading spinner
        }
    };

  const signInWithGoogle = async () => {
    setLoading(true);
    setError(null);

    try {
      const provider = new GoogleAuthProvider();
      const result = await signInWithPopup(auth, provider);

      const user = result.user;
      console.log(user)

      navigate("/search");
    } catch (error: any) {
      setError(await StringsService.getStringById("error-google-signin"));
      console.error(error.code, error.message);
    } finally {
      setLoading(false);
    }
  };

  const signInWithApple = async () => {
    setLoading(true);
    setError(null);

    try {
      const provider = new OAuthProvider("apple.com");
      const result = await signInWithPopup(auth, provider);

      const user = result.user;
      console.log(user)
      navigate("/search");
    } catch (error: any) {
      setError(await StringsService.getStringById("error-apple-signin"));
      console.error(error.code, error.message);
    } finally {
      setLoading(false);
    }
  };

    return (
        <div className='main-container'>
            <Container
                maxWidth="xs"
                sx={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    justifyContent: "center",
                    minHeight: "100vh",
                    background: "#f8f9fa",
                }}
            >
                <Box sx={{ textAlign: "center", mb: 2 }}>
                    <Typography variant="h4" component="h1" sx={{ fontWeight: "bold" }}>
                        <AsyncString stringId="website-name-upper-cammel-case" />
                    </Typography>
                </Box>

                <Box
                    component="form"
                    onSubmit={onLogin}
                    noValidate
                    sx={{
                        display: "flex",
                        flexDirection: "column",
                        gap: 2,
                        width: "100%",
                        background: "#fff",
                        borderRadius: 2,
                        padding: 3,
                        boxShadow: 3,
                    }}
                >
                    <TextField
                        id="email-address"
                        label={<AsyncString stringId="email-address" />}
                        variant="outlined"
                        fullWidth
                        required
                        type="email"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                    />
                    <TextField
                        id="password"
                        label={<AsyncString stringId="password" />}
                        variant="outlined"
                        fullWidth
                        required
                        type="password"
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                    />
                    <Box
                        sx={{
                            display: "flex",
                            justifyContent: "space-between",
                            alignItems: "center",
                        }}
                    >
                        <FormControlLabel
                            control={<Checkbox color="primary" />}
                            label={<AsyncString stringId="keep-me-signed-in" />}
                        />
                        <Link href="/forgotPassword" underline="hover" variant="body2" color="primary">
                            {<AsyncString stringId="forgot-password"/>}
                        </Link>
                    </Box>
                    <Button
                        type="submit"
                        variant="contained"
                        fullWidth
                        sx={{
                            backgroundColor: "#ffc107",
                            "&:hover": { backgroundColor: "#ffca28" },
                        }}
                    >
                        {loading ?
                            <CircularProgress size={24} color="inherit" /> :
                            <AsyncString stringId="sign-in" />}
                    </Button>
                </Box>

                
        <Typography variant="subtitle1" marginTop={3} textAlign="center">
          
          <AsyncString stringId="or-sign-in-with" />
        </Typography>
        <Stack direction="column" spacing={2} marginTop={2} width="100%">
          <Button
            variant="outlined"
            startIcon={<GoogleIcon />}
            onClick={signInWithGoogle}
            fullWidth
            sx={{
              color: "black",
              borderColor: "#dcdcdc",
              backgroundColor: "white",
              "&:hover": { backgroundColor: "#f5f5f5" },
            }}
          >
            <AsyncString stringId="signin-with-google" />
          </Button>
          <Button
            variant="outlined"
            startIcon={<AppleIcon />}
            onClick={signInWithApple}
            fullWidth
            sx={{
              color: "black",
              borderColor: "#dcdcdc",
              backgroundColor: "white",
              "&:hover": { backgroundColor: "#f5f5f5" },
            }}
          >
            <AsyncString stringId="signin-with-apple" />
          </Button>
        </Stack>

                <Typography variant="body2" sx={{ mt: 2 }}>
                    <Box
                        sx={{
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "center",
                        }}
                    >
                        <AsyncString stringId="no-have-account-account" />
                        <NavLink to="/signup" style={{ color: "#1976d2", textDecoration: "none" }}>
                            <AsyncString stringId="create-account" />
                        </NavLink>
                    </Box>
                </Typography>
                {/* Error Modal */}
                <Dialog open={!!error} onClose={() => setError(null)}>
                    <DialogTitle>
                        <AsyncString stringId="error" />
                    </DialogTitle>
                    <DialogContent>
                        <Typography>{error}</Typography>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={() => setError(null)} color="primary">
                            <AsyncString stringId="close" />
                        </Button>
                    </DialogActions>
                </Dialog>
            </Container>
        </div>
    );
};
