import React, { useState, useEffect } from "react";
import {
    Box,
    TextField,
    Button,
    Typography,
    Card,
    CardContent,
    CircularProgress,
} from "@mui/material";
import { ProfileType } from "../models/profileType";
import { OSMDisplayCard } from "./OSMDisplayCard";
import { ProfileService } from "../services/profile/profile";
import { useAuthRouting, useSignedInUserState } from "../hooks/useAuthState";
import { StringsService } from "../services/strings/strings";
import { queryNominatim } from "../services/location/nominatim";
import { useNavigate } from "react-router-dom";
import { emptyNominatim } from "../models/nominatim";
import AsyncString from "../shared/asyncString";

export const ProfileManagementPage = () => {
    useAuthRouting();
    const user = useSignedInUserState().user;
    const navigate = useNavigate();

    const [profile, setProfile] = useState<ProfileType>({
        id: "",
        name: "",
        email: "",
        profileType: "user",
        address: "",
        addressDetails: emptyNominatim,
        paymentDetails: "",
        createdAt: new Date().toISOString(),
    });

    const [loading, setLoading] = useState(false);
    const [fetchingAddressLoading, setFetchingAddressLoading] = useState(false);
    const [errorMessage, setErrorMessage] = useState<string | null>(null);
    const [successMessage, setSuccessMessage] = useState<string | null>(null);

    useEffect(() => {
        if (user !== null && profile.id === "" && !loading) {
            setLoading(true);
            ProfileService.getProfile(user.uid).then(async (user) => {
                if (user) {
                    setProfile(user);
                    setErrorMessage("");
                } else {
                    setErrorMessage(await StringsService.getStringById("error-unknown"));
                }
                setLoading(false);
            }).catch(async () => {
                setErrorMessage(await StringsService.getStringById("error-unknown"));
                setLoading(false);
            });
        }
    }, [user, loading, profile.id]);

    const handleInputChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        const { name, value } = e.target;
        setProfile({ ...profile, [name]: value });
    };

    const fetchAddressDetails = async () => {
        if (!profile.address) return;

        setFetchingAddressLoading(true);
        setErrorMessage(null); // Clear previous error messages before fetching
        try {
            const response = await queryNominatim(profile.address);
            const data = await response.json();

            if (data && data.length > 0) {
                setProfile((prevProfile) => ({
                    ...prevProfile,
                    addressDetails: data[0],
                }));
                setSuccessMessage(await StringsService.getStringById("success-address-fetch"));
                // Clear address error message if previously shown
                setErrorMessage(null);
            } else {
                // Clear the address if no results are found
                setProfile((prevProfile) => ({ ...prevProfile, addressDetails: emptyNominatim, address: "" }));
                setErrorMessage(await StringsService.getStringById("error-address-not-found"));
            }
        } catch (error) {
            setErrorMessage(await StringsService.getStringById("error-address-failure"));
        } finally {
            setFetchingAddressLoading(false);
        }
    };

    const handleSubmit = async (e: React.FormEvent) => {
        e.preventDefault();
        if (user) {
            setLoading(true);
            await new Promise(f => setTimeout(f, 3000));
            setErrorMessage(null);
            setSuccessMessage(null);

            try {
                await ProfileService.updateProfile(user?.uid, profile);
                setSuccessMessage(await StringsService.getStringById("success-profile-update"));
            } catch (error) {
                setErrorMessage(await StringsService.getStringById("error-profile-update"));
            } finally {
                setLoading(false);
            }
        }
    };

    if (loading) {
        return (
            <Box
                sx={{
                    backgroundColor: "#f5f5f5",
                    minHeight: "100vh",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    padding: 2,
                }}
            >
                <CircularProgress size={60} />
            </Box>
        );
    }

    return (
        <Box
            sx={{
                backgroundColor: "#f5f5f5",
                minHeight: "100vh",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                padding: 2,
            }}
        >
            <Card
                sx={{
                    width: "100%",
                    maxWidth: 500,
                    borderRadius: 2,
                    boxShadow: 3,
                    backgroundColor: "white",
                }}
            >
                <CardContent>
                    <Typography
                        variant="h5"
                        textAlign="center"
                        marginBottom={2}
                        sx={{ fontWeight: "bold" }}
                    >
                        Profile Management
                    </Typography>

                    <Box component="form" onSubmit={handleSubmit} sx={{ width: "100%" }}>
                        
                    <TextField
                            label={<AsyncString stringId={"full-name"} />}
                            name="name"
                            value={profile.name}
                            onChange={handleInputChange}
                            fullWidth
                            margin="normal"
                        />
                        {/* Address */}
                        <TextField
                            label={<AsyncString stringId={"address"} />}
                            name="address"
                            value={profile.address}
                            onChange={handleInputChange}
                            fullWidth
                            margin="normal"
                        />
                        <Button
                            onClick={fetchAddressDetails}
                            variant="contained"
                            fullWidth
                            sx={{
                                marginBottom: 2,
                                backgroundColor: "#FFD700",
                                color: "black",
                                "&:hover": { backgroundColor: "#FFC300" },
                            }}
                            disabled={loading}
                        >
                            {fetchingAddressLoading ? (
                                <CircularProgress size={24} sx={{ color: "black" }} />
                            ) : (
                                <AsyncString stringId="fetch-address" />
                            )}
                        </Button>

                        {/* Address Details */}
                        <OSMDisplayCard addressDetails={profile.addressDetails} />

                        {
                            (profile.profileType === "user" || profile.profileType === "owner") &&
                            <Button
                                type="button"
                                onClick={() => navigate("/restaurantManagement")}
                                variant="contained"
                                fullWidth
                                sx={{
                                    marginTop: 2,
                                    height: "50px",
                                    backgroundColor: "#FFD700",
                                    color: "black",
                                    "&:hover": { backgroundColor: "#FFC300" },
                                }}
                                disabled={loading}>
                                <AsyncString stringId="manage-restaurants" />
                            </Button>
                        }

                        {/* Submit Button */}
                        <Button
                            type="submit"
                            variant="contained"
                            fullWidth
                            sx={{
                                marginTop: 2,
                                height: "50px",
                                backgroundColor: "#FFD700",
                                color: "black",
                                "&:hover": { backgroundColor: "#FFC300" },
                            }}
                            disabled={loading}
                        >
                            <AsyncString stringId="update-profile" />
                        </Button>
                    </Box>

                    {successMessage && (
                        <Typography color="success.main" marginTop={2} textAlign="center">
                            {successMessage}
                        </Typography>
                    )}

                    {errorMessage && (
                        <Typography color="error.main" marginTop={2} textAlign="center">
                            {errorMessage}
                        </Typography>
                    )}
                </CardContent>
            </Card>
        </Box>
    );
};
