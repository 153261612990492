import { getFirestore, doc, getDoc, setDoc } from "firebase/firestore";
import { ImageGalleryType } from "../../models/imageGallery";
import { getStorage, ref, uploadBytes, getDownloadURL } from "firebase/storage";
import { firestore, storage } from "../../firebase";
import { v4 as uuidv4 } from "uuid";



// Initialize Firestore
const IMAGES_COLLECTION_NAME = "image-collections";


const uploadImagesToStorage = async (files: File[]): Promise<string[]> => {
    const urls: string[] = [];
    const seenHashes = new Set<string>(); // To detect duplicates

    for (const file of files) {
        // Generate a hash for deduplication (e.g., based on file content)
        const fileArrayBuffer = await file.arrayBuffer();
        const hash = await crypto.subtle.digest("SHA-256", fileArrayBuffer);
        const hashString = Array.from(new Uint8Array(hash))
            .map((byte) => byte.toString(16).padStart(2, "0"))
            .join("");

        if (seenHashes.has(hashString)) {
            console.log("Duplicate file detected, skipping upload:", file.name);
            continue;
        }
        seenHashes.add(hashString);

        // Generate a UUID for the filename to prevent naming collisions
        const uniqueFilename = `${uuidv4()}`;

        // Upload to Firebase Storage
        const storageRef = ref(storage, `gallery-images/${uniqueFilename}`);
        const snapshot = await uploadBytes(storageRef, file);
        const downloadURL = await getDownloadURL(snapshot.ref);
        urls.push(downloadURL);
    }

    return urls;
};


const getGalleryImages = async (propertyId: string): Promise<ImageGalleryType[]> => {
    try {
        const docRef = doc(firestore, IMAGES_COLLECTION_NAME, propertyId);
        const docSnap = await getDoc(docRef);

        if (docSnap.exists()) {
            console.log("Fetched images for property ID:", propertyId);
            return docSnap.data().images.map((i: any) => i as ImageGalleryType) || [];
        } else {
            console.log("No images found for property ID:", propertyId);
            return [];
        }
    } catch (error) {
        console.error("Error fetching images:", error);
        return [];
    }
};

const setGalleryImages = async (propertyId: string, images: ImageGalleryType[]) => {
    try {
        const docRef = doc(firestore, IMAGES_COLLECTION_NAME, propertyId);
        await setDoc(docRef, { images });
        console.log("Images saved for property ID:", propertyId);
    } catch (error) {
        console.error("Error saving images:", error);
    }
};

const ImageService = {
    uploadImagesToStorage,
    getGalleryImages,
    setGalleryImages,
};

export { ImageService };
