import React, { useState } from 'react';
import { NavLink, useNavigate } from 'react-router-dom';
import { createUserWithEmailAndPassword } from 'firebase/auth';
import { auth } from '../firebase';
import {
    Container,
    Box,
    Typography,
    TextField,
    Button,
    Checkbox,
    FormControlLabel,
    CircularProgress,
    Dialog,
    DialogContent,
    DialogTitle,
    DialogActions
} from "@mui/material";
import "./auth.css";
import { StringsService } from '../services/strings/strings';
import AsyncString from '../shared/asyncString';

export const SignupPage = () => {
    const navigate = useNavigate();

    const [email, setEmail] = useState('')
    const [password, setPassword] = useState('');
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState<string | null>(null);

    const onSignin = async (e: any) => {
        e.preventDefault();
        if (loading) {
            return;
        }
        setLoading(true); // Show loading spinner
        setError(null); // Clear previous errors

        try {
            const userCredential = await createUserWithEmailAndPassword(auth, email, password)
            // .then((userCredential) => {
            //     // Signed in
            //     const user = userCredential.user;
            //     console.log(user);
            //     navigate("/search")
            //     // ...
            // })
            // .catch((error) => {
            //     const errorCode = error.code;
            //     const errorMessage = error.message;
            //     console.log(errorCode, errorMessage);
            //     // ..
            // });
            const user = userCredential.user;
            console.log(user);
            console.log(user);
            navigate("/search");
        } catch (error: any) {
            // Handle specific Firebase error codes
            if (error.code === "auth/email-already-in-use") {
                setError(await StringsService.getStringById("error-email-exists"));
            } else if (error.code === "auth/weak-password") {
                setError(await StringsService.getStringById("error-weak-password"));
            } else if (error.code === "auth/network-request-failed") {
                setError(await StringsService.getStringById("error-network"));
            } else {
                setError(await StringsService.getStringById("error-unknown"));
            }
            console.error(error.code, error.message);
        } finally {
            setLoading(false); // Hide loading spinner
        }

    }

    return (
        <div className='main-container'>
            <Container
                maxWidth="xs"
                sx={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    justifyContent: "center",
                    minHeight: "100vh",
                    background: "#f8f9fa",
                }}
            >
                <Box sx={{ textAlign: "center", mb: 2 }}>
                    <Typography variant="h4" component="h1" sx={{ fontWeight: "bold" }}>
                        <AsyncString stringId="website-name-upper-cammel-case" />
                    </Typography>
                </Box>

                <Box
                    component="form"
                    onSubmit={onSignin}
                    noValidate
                    sx={{
                        display: "flex",
                        flexDirection: "column",
                        gap: 2,
                        width: "100%",
                        background: "#fff",
                        borderRadius: 2,
                        padding: 3,
                        boxShadow: 3,
                    }}
                >
                    <TextField
                        id="email-address"
                        label={<AsyncString stringId="email-address" />}
                        variant="outlined"
                        fullWidth
                        required
                        type="email"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                    />
                    <TextField
                        id="password"
                        label={<AsyncString stringId="password" />}
                        variant="outlined"
                        fullWidth
                        required
                        type="password"
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                    />
                    <Box
                        sx={{
                            display: "flex",
                            justifyContent: "space-between",
                            alignItems: "center",
                        }}
                    >
                        <FormControlLabel
                            control={<Checkbox color="primary" />}
                            label={<AsyncString stringId="keep-me-signed-in" />}
                        />
                    </Box>
                    <Button
                        type="submit"
                        variant="contained"
                        fullWidth
                        sx={{
                            backgroundColor: "#ffc107",
                            "&:hover": { backgroundColor: "#ffca28" },
                        }}
                    >
                        {loading ?
                            <CircularProgress size={24} color="inherit" /> :
                            <AsyncString stringId="sign-up" />}
                    </Button>
                </Box>

                <Typography variant="body2" sx={{ mt: 2 }}>
                    <Box
                        sx={{
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "center",
                        }}
                    >
                        <AsyncString stringId="already-have-account-account" />
                        <NavLink to="/login" style={{ color: "#1976d2", textDecoration: "none" }}>
                            <AsyncString stringId="sign-in" />
                        </NavLink>
                    </Box>
                </Typography>
                {/* Error Modal */}
                <Dialog open={!!error} onClose={() => setError(null)}>
                    <DialogTitle>
                        <AsyncString stringId="error" />
                    </DialogTitle>
                    <DialogContent>
                        <Typography>{error}</Typography>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={() => setError(null)} color="primary">
                            <AsyncString stringId="close" />
                        </Button>
                    </DialogActions>
                </Dialog>
            </Container>
        </div>
    );
};