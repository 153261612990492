export interface Nominatim {
    place_id: number; // Unique identifier for the place
    licence: string; // Licensing information
    osm_type: string; // Type of OSM entity (e.g., "way")
    osm_id: number; // OSM ID of the entity
    lat: string; // Latitude of the place
    lon: string; // Longitude of the place
    category: string; // Category of the place (e.g., "highway")
    type: string; // Specific type (e.g., "residential")
    place_rank: number; // Rank of the place
    importance: number; // Importance score of the place
    addresstype: string; // Address type (e.g., "road")
    name?: string; // Name of the place (optional, can be undefined)
    display_name: string; // Full display name of the place
    boundingbox: string[]; // Bounding box coordinates [south, north, west, east]
  }

  export const emptyNominatim: Nominatim = {
    place_id: 0,
    licence: "",
    osm_type: "",
    osm_id: 0,
    lat: "",
    lon: "",
    category: "",
    type: "",
    place_rank: 0,
    importance: 0,
    addresstype: "",
    name: "",
    display_name: "",
    boundingbox: []
  }