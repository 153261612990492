import { emptyNominatim, Nominatim } from "./nominatim";
import { SaleItem } from "./saleItem";

export interface FullProperty {
    id: string;
    name: string;
    location: Nominatim;
    isActive: boolean;
    isPublished: boolean;
    franchise: string;
    saleItems: SaleItem[];
    owner: string; // the customer.uid 
    staff: string[];
}

export const emptyProperty: FullProperty = {
    id: "",
    name: "",
    location: emptyNominatim,
    isActive: false,
    isPublished: false,
    franchise: "",
    saleItems: [],
    owner: "",
    staff: []
}