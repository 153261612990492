import { auth } from "../../firebase";
import { ProfileService } from "../profile/profile";

const getUidByEmail = async (email: string): Promise<string | null> => {
  const user = await ProfileService.getProfileByEmail(email)

  if (user) {
    return user.id;
  }

  return null; // User not found
};

export const userService = {
  getUidByEmail,
};
