// Import necessary libraries and components
import React from 'react';
import { Helmet } from 'react-helmet';
import "./index.css"

const EcoEatsPage: React.FC = () => {
    return (
        <div style={{ fontFamily: 'Arial, sans-serif', lineHeight: '1.5' }}>
            <Helmet>
                <title>Eco Eats</title>
                <meta name="description" content="Join 'Eco Eats' to reduce food waste, fight hunger, and build a sustainable tomorrow." />
                <meta name="viewport" content="width=device-width, initial-scale=1.0" />
            </Helmet>

            {/* Header Section
            <header style={{ backgroundColor: '#000', color: '#fff', padding: '1rem', display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                <h1 className='LatoText'>Eco Eats</h1>
            </header> */}

            {/* Hero Section */}
            <section
                style={{
                    backgroundColor: '#000',
                    color: '#fff',
                    textAlign: 'center',
                    padding: '6rem 2rem',
                    backgroundImage: " linear-gradient( rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5) ), url(/landingPageAssets/unsplash-image-bAcMAhWciiM.jpg)",
                    backgroundPosition: "center",
                    backgroundRepeat: "no-repeat",
                    backgroundSize: "cover",
                    background: "blur(5px)",
                    height: '100vh'
                }}
            >
                <h2 style={{ fontSize: '60px', fontWeight: '700', textShadow: "0px 4px 6px rgba(0,0,0,.4)", marginBottom: '1rem' }}>
                    Turn Surplus Food into a Better Future
                </h2>
                <p className='LatoText'>
                    Join 'Eco Eats' and make a difference—reduce waste, fight hunger, and build a sustainable tomorrow.
                </p>
                <a
                    style={{
                        backgroundColor: 'transparent',
                        border: '1px solid #fff',
                        color: '#fff',
                        padding: '0.75rem 2rem',
                        cursor: 'pointer',
                        borderRadius: '25px',
                        fontSize: '18px',
                        fontWeight: '400',
                    }}
                        href='https://docs.google.com/forms/d/e/1FAIpQLSfrmE3fXhLjrkKX7yrlsyPaoWMOXOMztLTOX16T_eA0Px9LjA/viewform'
                >
                    Contact us
                </a>
            </section>

            {/* About Section */}
            <section style={{ backgroundColor: '#f9f9f9', padding: '5rem 2rem', textAlign: 'center' }}>
                <div
                    style={{
                        display: 'flex',
                        flexWrap: "wrap",
                        alignItems: 'center',
                        gap: '2rem',
                        textAlign: 'left',
                    }}
                >
                    <div style={{ flex: 1, minWidth: '300px', maxWidth: '700px', textAlign: 'center' }}>
                        <h2 className="MontserratText">WHO <br />WE ARE</h2>
                        <p className='LatoText'>
                            At 'Eco Eats', we believe in a world where surplus food is a resource, not waste. Our mission is simple yet impactful:
                            to reduce food waste, fight hunger, and create a sustainable future for everyone. By connecting businesses with
                            surplus food to people who value sustainability, we make a difference—one meal at a time.
                        </p>
                    </div>
                    <div style={{ flex: 1, minWidth: '300px', maxWidth: '700px' }}>

                        <img
                            src="/landingPageAssets/unsplash-image-6WrKKQcEnXk.jpg"
                            alt="Eco Eats mission"
                            style={{ width: '100%', maxWidth: '600px', margin: '2rem auto', display: 'block', borderRadius: '15px' }}
                        />
                    </div>
                </div>
                <div style={{ flex: 1, minWidth: '300px', maxWidth: '700px' }}>
                    <img
                        src="/landingPageAssets/unsplash-image-urcDzLL0HnI.jpg"
                        alt="Eco Eats mission"
                        style={{ width: '100%', maxWidth: '600px', margin: '2rem auto', display: 'block', borderRadius: '15px' }}
                    />
                </div>
            </section >

            {/* Subscription Section */}
            < section
                style={{
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    alignContent: 'center',
                    textAlign: 'center',
                    padding: '5rem 2rem',
                    backgroundImage: 'url(/landingPageAssets/unsplash-image-TyLw3IQALMs.jpg)',
                    backgroundSize: 'cover',
                    backgroundPosition: 'center',
                    height: '100vh'
                }}
            >
                <div
                    style={{
                        backgroundColor: '#fff',
                        padding: '2rem',
                        maxWidth: '700px',
                        margin: '0 auto',
                        boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
                        borderRadius: '0',
                    }}
                >
                    <h2 style={{ fontWeight: '700', fontSize: '2rem', marginBottom: '1rem' }}>Subscribe</h2>
                    <p style={{ maxWidth: '700px', margin: '1.5rem auto', color: '#333', fontSize: '16px', lineHeight: '1.8' }}>
                        Be the first to know! Subscribe to stay updated on our mission to reduce food waste, fight hunger, and build a
                        sustainable future. Join us in making a difference, one meal at a time.
                    </p>
                    <a
                        style={{
                            backgroundColor: 'transparent',
                            border: '1px solid #000',
                            color: '#000',
                            padding: '0.75rem 2rem',
                            cursor: 'pointer',
                            borderRadius: '25px',
                            fontSize: '1rem',
                            fontWeight: '400',
                        }}
                        href='https://docs.google.com/forms/d/e/1FAIpQLSfrmE3fXhLjrkKX7yrlsyPaoWMOXOMztLTOX16T_eA0Px9LjA/viewform'
                    >
                        Subscribe
                    </a>
                </div>
            </section >

            {/* Footer Section */}
            < footer
                style={{
                    backgroundColor: '#f9f9f9',
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'space-around',
                    color: '#333',
                    textAlign: 'center',
                    padding: '2rem 1rem',
                    borderTop: '1px solid #ddd',
                }}
            >
                <p className='LatoText'>Eco Eats</p>
                <a href='mailto:info@ecoeats.co.za' className='LatoText'>info@ecoeats.co.za</a>
            </footer >
        </div >
    );
};

export default EcoEatsPage;
