import React, { useState } from "react";
import { sendPasswordResetEmail } from "firebase/auth";
import { auth } from "../firebase";
import {
    Container,
    Box,
    TextField,
    Button,
    Typography,
    CircularProgress,
    Card,
    CardContent,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import { StringsService } from "../services/strings/strings";
import AsyncString from "../shared/asyncString";

export const ForgotPasswordPage = () => {
    const [email, setEmail] = useState("");
    const [loading, setLoading] = useState(false);
    const [successMessage, setSuccessMessage] = useState<string | null>(null);
    const [errorMessage, setErrorMessage] = useState<string | null>(null);

    const navigate = useNavigate();

    const handleForgotPassword = async (e: React.FormEvent) => {
        e.preventDefault();
        setLoading(true);
        setSuccessMessage(null);
        setErrorMessage(null);

        try {
            await sendPasswordResetEmail(auth, email);
            setSuccessMessage(await StringsService.getStringById("success-password-reset-email"));
        } catch (error: any) {
            if (error.code === "auth/user-not-found") {
                setErrorMessage(await StringsService.getStringById("error-user-not-found"));
            } else if (error.code === "auth/invalid-email") {
                setErrorMessage(await StringsService.getStringById("error-email-invalid"));
            } else {
                setErrorMessage(await StringsService.getStringById("error-email-reset-generic"));
            }
        } finally {
            setLoading(false);
        }
    };

    return (
        <Box
            sx={{
                backgroundColor: "#f5f5f5", // Matches the background of the login page
                minHeight: "100vh",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                padding: 2,
            }}
        >
            <Card
                sx={{
                    width: "100%",
                    maxWidth: 400,
                    borderRadius: 2,
                    boxShadow: 3,
                    backgroundColor: "white",
                }}
            >
                <CardContent>
                    <Typography
                        variant="h5"
                        textAlign="center"
                        marginBottom={2}
                        sx={{ fontWeight: "bold" }}
                    >
                        <AsyncString stringId="forgot-password" />
                    </Typography>
                    <Typography
                        variant="body2"
                        marginBottom={2}
                        textAlign="center"
                        sx={{ color: "#6c757d" }}
                    >
                        <AsyncString stringId="email-reset-description" />

                    </Typography>

                    <Box component="form" onSubmit={handleForgotPassword}>
                        <TextField
                            label={<AsyncString stringId="email-address"/>}
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                            fullWidth
                            required
                            margin="normal"
                            variant="outlined"
                        />
                        <Button
                            type="submit"
                            variant="contained"
                            fullWidth
                            sx={{
                                marginTop: 2,
                                height: "50px",
                                backgroundColor: "#FFD700", // Matches the login page button color
                                color: "black",
                                "&:hover": { backgroundColor: "#FFC300" },
                            }}
                            disabled={loading}
                        >
                            {loading ?
                                <CircularProgress size={24} sx={{ color: "black" }} /> :
                                <AsyncString stringId="send-reset-email" />}
                        </Button>
                    </Box>

                    {successMessage && (
                        <Typography color="success.main" marginTop={2} textAlign="center">
                            {successMessage}
                        </Typography>
                    )}

                    {errorMessage && (
                        <Typography color="error.main" marginTop={2} textAlign="center">
                            {errorMessage}
                        </Typography>
                    )}

                    <Button
                        variant="text"
                        sx={{
                            marginTop: 3,
                            textAlign: "center",
                            color: "#007bff",
                            fontWeight: "bold",
                            textTransform: "none",
                            "&:hover": { textDecoration: "underline" },
                        }}
                        onClick={() => navigate("/login")}
                    >
                        <AsyncString stringId="back-to-login" />
                    </Button>
                </CardContent>
            </Card>
        </Box>
    );
};
