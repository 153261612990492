import * as React from 'react';
import { useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Typography from '@mui/material/Typography';
import { Button, CardActionArea, CardActions, createTheme } from '@mui/material';
import { FullProperty } from '../../models/fullProperty';
import "./index.css"
import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';
import AsyncString from '../../shared/asyncString';

interface IProps {
    listing: FullProperty
}

function DesktopCard({ listing }: IProps) {
    return (
        <div className='ListingCardBase'>
            <Card sx={{ backgroundColor: "#F0FFFF", display: 'flex', maxHeight: "230px", width: "100%" }} >
                <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                    <CardContent>
                        <div className="flex-row jcsb">
                            <div className="flex-column">
                                <Typography variant="h5" component="div">
                                    <AsyncString stringId={listing.id + "-restaurant-surprise-box-price-zar-displayable"} />
                                </Typography>
                                <Typography variant="subtitle1" component="div">
                                    <AsyncString stringId={listing.id + "-short-property-summary"} />
                                </Typography>
                            </div>
                        </div>
                        < div className="p8" />
                        {/* TODO: Update wit dietry requirements icons  */}
                        {/* <div className="flex-row ">
                            <div className='pr-16'>
                                <KingBedIcon />
                            </div>
                            <div className='pr-16'>
                                {listing.bedroomCount}
                            </div>
                            <div className='pr-16'>
                                <BathtubIcon />
                            </div>
                            <div className='pr-16'>
                                {listing.bathroomCount}
                            </div>
                            <div className='pr-16'>
                                <DirectionsCarIcon />
                            </div>
                            <div className='pr-16'>
                                {listing.carCount}
                            </div>
                            <div className='pr-16'>
                                <SquareFootIcon />
                            </div>
                            <div className='pr-16'>
                                {listing.houseSize}
                                {" "}
                                <AsyncString stringId={"meters_squared"} />
                            </div>
                        </div> */}
                    </CardContent>
                </Box>
            </Card>
        </div>
    );
}

function ListingSummaryCard({ listing }: IProps) {
    return < DesktopCard listing={listing} />;

}

export { ListingSummaryCard };