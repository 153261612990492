import React, { useState, useEffect } from "react";
import { getFirestore, doc, getDoc, setDoc } from "firebase/firestore";
import { Box, Button, CircularProgress, IconButton, TextField, Typography } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";
import { CartItem, CartType } from "../models/CartType";
import { SaleItem } from "../models/saleItem";
import { useCart } from "../hooks/contexts/cartContext";

type BuyButtonProps = {
    restaurantId: string;
    saleItem: SaleItem;
};

const emptyCartItem = (restaurantId: string, saleItem: SaleItem): CartItem => {
    return {
        addedAt: new Date(),
        quantity: 0,
        restaurantId,
        saleItem
    }
}

const BuyButton: React.FC<BuyButtonProps> = ({ restaurantId, saleItem }) => {
    const [cartItem, setCartItem] = useState<CartItem>(emptyCartItem(restaurantId, saleItem));
    const [isLoading, setIsLoading] = useState(false);
    const [disableIncrement, setDisableIncrement] = useState(false);
    const { cart, removeItem, addItem } = useCart();

    const fetchCartItem = async () => {
        try {
            setIsLoading(true);
            if (cart) {
                let cartSet = false;
                cart.items.forEach((item: CartItem) => {
                    if (item.saleItem.id === saleItem.id) {
                        setCartItem(item);
                        cartSet = true;
                        if (item.quantity >= saleItem.available) {
                            setDisableIncrement(true)
                        }
                    }
                })
                if (!cartSet) {
                    setCartItem(emptyCartItem(restaurantId, saleItem))    
                }
            } else {
                setCartItem(emptyCartItem(restaurantId, saleItem))
            }
            setIsLoading(false);
        } catch (error) {
            console.error("Error fetching cart item:", error);
        }
    };

    const updateCart = async (newQuantity: number) => {
        try {
            setIsLoading(true);
            setDisableIncrement(false);
            if (newQuantity === 0) {
                await removeItem(restaurantId, saleItem)
            } else {
                await addItem(
                    newQuantity,
                    restaurantId,
                    saleItem)
            }
        } catch (error: any) {
            console.error("Error updating cart:", error.message);
            if (error.message === "Invalid not enough stock to fill cart") {
                setDisableIncrement(true);
                await updateCart(saleItem.available)
            }
        } finally {
            setIsLoading(false);
        }
    };

    const handleIncrement = () => {
        const newQuantity = (cartItem.quantity || 0) + 1;
        console.log(newQuantity)
        updateCart(newQuantity);
    };

    const handleDecrement = () => {
        if (cartItem.quantity > 0) {
            const newQuantity = cartItem.quantity - 1;
            updateCart(newQuantity);
        }
    };

    useEffect(() => {
        fetchCartItem();
    }, [cart]);

    if (isLoading) {
        return <CircularProgress size={24} sx={{ color: "black" }} />
    }

    const getBuyButton = () => {
        if (cartItem.quantity >= saleItem.available) {
            return (
                <Typography color="error.main" marginTop={2} textAlign="center">
                    Sold Out
                </Typography>
            )
        }
        return (<Button variant="contained" onClick={handleIncrement} disabled={isLoading}>
            Buy
        </Button>)
    }

    if (cartItem.quantity === 0) {
        return <Box display="flex" alignItems="center" gap={1}>
            {getBuyButton()}
        </Box>
    }


    return (
        <Box display="flex" alignItems="center" gap={1}>
            <IconButton
                color="primary"
                onClick={handleDecrement}
                disabled={isLoading || cartItem.quantity === 0}
            >
                <RemoveIcon />
            </IconButton>
            <TextField
                value={cartItem.quantity}
                inputProps={{ readOnly: true }}
                variant="outlined"
                size="small"
                style={{ width: "50px", textAlign: "center" }}
            />
            <IconButton color="primary" onClick={handleIncrement} disabled={isLoading || disableIncrement}>
                {disableIncrement ?
                    <AddIcon color="disabled" /> :
                    <AddIcon />}
            </IconButton>
        </Box>
    );
};

export default BuyButton;
