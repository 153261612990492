import { FirestoreService } from "../firebase/firestoreService";
import { ProfileType } from "../../models/profileType";
import { where } from "firebase/firestore";

const COLLECTION_NAME = "profiles";

export const ProfileService = {
  async getProfile(profileId: string): Promise<ProfileType | null> {
    const data = await FirestoreService.getDocument(COLLECTION_NAME, profileId);
    return data as ProfileType | null;
  },

  async updateProfile(profileId: string, updatedData: Partial<ProfileType>): Promise<void> {
    await FirestoreService.updateDocument(COLLECTION_NAME, profileId, updatedData);
  },


  async getProfileByEmail(email: string): Promise<ProfileType | null> {
    try {
      const querySnapshot = await FirestoreService.queryCollection(COLLECTION_NAME, where("email", "==", email));
      if (querySnapshot.empty) {
        return null; // No profile found for the provided email
      }

      // Return the first matching user 
      let user: ProfileType | null = null;
      querySnapshot.forEach((doc) => {
        user = (doc as any) as ProfileType;
      });
      return user;
    } catch (error) {
      console.error("Error fetching UID by email:", error);
      throw new Error("Failed to fetch UID. Please try again later.");
    }
  },
};
