import React from "react";
import ReactDOM from 'react-dom/client';
import './index.css';
import reportWebVitals from './reportWebVitals';
import {
  createBrowserRouter,
  RouterProvider,
} from "react-router-dom";
import ListingSearchPage from './listingSearchPage';
import ListingDetailsPage from './listingDetailsPage';
import ReactGA from "react-ga4";
import { SignupPage } from './auth/signup';
import { LoginPage } from './auth/login';
import { ForgotPasswordPage } from './auth/forgotPassword';
import { ProfileManagementPage } from './profilePage/profileManagementPage';
import RestaurantGlobalManagementPage from './profilePage/restaurantGlobalManagementPage';
import { CreateRestaurantPage } from './profilePage/createRestaurantPage';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import WithProviders from "./WithProviders";
import RestaurantStaffManagementPage from "./profilePage/restaurantStaffManagementPage";
import MessagesPage from "./messagesPage/messagesPage";
import MaintainRestaurantsPage from "./profilePage/maintainRestaurantsPage";
import MaintainSpecificRestaurantPage from "./profilePage/maintainSpecificRestaurantPage";
import { CartProvider } from "./hooks/contexts/cartContext";
import OrderConfirmPage from "./checkout/orderConfirm";
import OrderSuccessPage from "./checkout/orderSuccessful";
import LandingPage from "./landingPage";

const router = createBrowserRouter([
  {
    path: "/",
    element: <LandingPage />,
  },
  {
    path: "/login",
    element: <WithProviders><LoginPage /></WithProviders>,
  },
  {
    path: "/signup",
    element: <WithProviders><SignupPage /></WithProviders>,
  },
  {
    path: "/forgotPassword",
    element: <WithProviders><ForgotPasswordPage /></WithProviders>,
  },
  {
    path: "/profile",
    element: <WithProviders><ProfileManagementPage /></WithProviders>,
  },
  {
    path: "/restaurantManagement",
    element: <WithProviders><RestaurantGlobalManagementPage /></WithProviders>,
  },
  {
    path: "/restaurantManagement/new",
    element: <WithProviders><CreateRestaurantPage /></WithProviders>,
  },
  {
    path: "/restaurantManagement/edit/:id",
    element: <WithProviders><CreateRestaurantPage /></WithProviders>,
  },
  {
    path: "/restaurantStaffManagement",
    element: <WithProviders><RestaurantStaffManagementPage /></WithProviders>,
  },
  {
    path: "/maintainRestaurant",
    element: <WithProviders><MaintainRestaurantsPage /></WithProviders>,
  },
  {
    path: "/maintainRestaurant/:id",
    element: <WithProviders><MaintainSpecificRestaurantPage /></WithProviders>,
  },
  {
    path: "/search",
    element: <WithProviders><ListingSearchPage /></WithProviders>,
  },
  {
    path: "/listing/:id",
    element: <WithProviders><ListingDetailsPage /></WithProviders>,
  },
  {
    path: "/messages",
    element: <WithProviders><MessagesPage /></WithProviders>,
  },
  {
    path: "/order/confirm",
    element: <WithProviders><OrderConfirmPage /></WithProviders>,
  },
  {
    path: "/order/success",
    element: <WithProviders><OrderSuccessPage /></WithProviders>,
  }
]);

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

ReactGA.initialize("G-6CG2VE6C80");


const theme = createTheme();

root.render(
  <React.StrictMode>

    <ThemeProvider theme={theme}>
        <RouterProvider router={router} />
    </ThemeProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
