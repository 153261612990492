import { profile } from "console";
import { FullProperty } from "../../models/fullProperty";
import { MessageType } from "../../models/message";
import { ProfileService } from "../profile/profile";
import { messageService } from "./messageService";
import { restaurantService } from "./restaurantManagementService";


export const staffService = {
acceptStaffInvitation: async (userId: string, restaurantId: string, message: MessageType): Promise<void> => {
    try {
      if (!message.id || !message.isActive) {
        console.error("Message already actioned:");
        throw new Error("Message already actioned.");
        return;
      }
      // Update user profile to staff
      await ProfileService.updateProfile(userId, { profileType: "staff" });

      // add staff to restaurant
      const restaurant = await restaurantService.fetchRestaurantById(restaurantId);
      if (restaurant.staff.indexOf(userId) === -1) {
        restaurant.staff.push(userId)
        await restaurantService.editRestaurant(restaurant.id, { staff: restaurant.staff })
      }

      // Set Message to inactive
        await messageService.updateMessage(userId, message.id, {isActive: false})
    } catch (error) {
      console.error("Error accepting invitation:", error);
      throw new Error("Failed to accept invitation. Please try again later.");
    }
  },

  removeStaffFromRestaurant: async (staffId: string, restaurant: FullProperty): Promise<void> => {

    // Remove staff from restaurant's staff array
    await restaurantService.editRestaurant(restaurant.id, {staff: 
        restaurant.staff.filter(s => { return s !== staffId })
    })

    // Check if the staff member is part of any other restaurant
    const restaurantsStaffWorksAt = await restaurantService.fetchRestaurantsByStaff(staffId);
    if (restaurantsStaffWorksAt.length === 0) {
        ProfileService.updateProfile(staffId, {profileType: "user"})
    }
  },
}