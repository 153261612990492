import React from "react";
import {Header} from "./header/index";
import { CartProvider } from "./hooks/contexts/cartContext";

interface WithHeaderProps {
  children: React.ReactNode;
}

const WithProviders: React.FC<WithHeaderProps> = ({ children }) => {
  return (
    
    <CartProvider>
      <Header />
      <main>{children}</main>
    </CartProvider>
  );
};

export default WithProviders;