import React, { useEffect, useState } from "react";
import { Typography, List, ListItem, ListItemText, Divider, Button, Box, CircularProgress } from "@mui/material";
import { useCart } from "../hooks/contexts/cartContext";
import { StringsService } from "../services/strings/strings";

const OrderConfirmPage = () => {
    const [cartItemLabels, setCartItemLabels] = useState<{ [cartItemId: string]: string }>({})
    const { cart, checkout } = useCart();

    useEffect(() => {
        const getStrings = async () => {
            if (cart) {
                let newCartItemLabels: { [cartItemId: string]: string } = {}
                const promises = cart.items.map(async (element) => {
                    const itemName = await StringsService.getStringById("restaurant-" + cart.restaurantId + "-saleItem-" + element.saleItem.id + "-sale-item-name");
                    newCartItemLabels[element.saleItem.id] = `${itemName} ${element.quantity}x`
                });
                await Promise.all(promises)
                setCartItemLabels(newCartItemLabels)
            }
        }
        // Cleanup subscription on unmount
        getStrings();
    }, [cart]);

    if (!cart) {
        return <Box
            sx={{
                backgroundColor: "#f5f5f5",
                minHeight: "100vh",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                padding: 2,
            }}
        >
            <CircularProgress size={60} />
        </Box>;
    }

    return (
        <div style={{ padding: "2rem" }}>
            <Typography variant="h4" gutterBottom>
                Confirm Your Order
            </Typography>
            <List>
                {cart.items.map((item) => (
                    <React.Fragment key={item.saleItem.id}>
                        <ListItem>
                            <ListItemText
                                primary={cartItemLabels[item.saleItem.id]}
                                secondary={`R${item.saleItem.price * item.quantity / 100}`}
                            />
                        </ListItem>
                        <Divider />
                    </React.Fragment>
                ))}
            </List>
            <Typography variant="h5" style={{ marginTop: "1rem" }}>
                Total: R{cart.totalAmount / 100}
            </Typography>
            <Button
                onClick={checkout}
                variant="contained" color="success" style={{ marginTop: "1rem" }}>
                Confirm Purchase
            </Button>
        </div>
    );
};

export default OrderConfirmPage;
