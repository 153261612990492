import React, { useState } from 'react';
import "./index.css"
import Card from '@mui/material/Card';
import { CardMedia, Typography } from '@mui/material';
import { StringsService } from '../../services/strings/strings';

import {
    EmailIcon,
    EmailShareButton,
    FacebookIcon,
    FacebookShareButton,
    GabShareButton,
    HatenaShareButton,
    InstapaperShareButton,
    LineShareButton,
    LinkedinShareButton,
    LivejournalShareButton,
    MailruShareButton,
    OKShareButton,
    PinterestShareButton,
    PocketShareButton,
    RedditIcon,
    RedditShareButton,
    TelegramIcon,
    TelegramShareButton,
    TumblrShareButton,
    TwitterShareButton,
    ViberShareButton,
    VKShareButton,
    WhatsappIcon,
    WhatsappShareButton,
    WorkplaceShareButton,
} from "react-share";


interface IProps {
    id: string
}


function ShareCard(props: IProps) {

    const shareURL = "https://casagrab.com/listing/" + props.id;

    return (
        <Card sx={{ backgroundColor: "#F0FFFF", justifyContent: "center", display: "flex !important", flexDirection: "column" }}>
            <div className='row p8 jcc'>
                <FacebookShareButton url={shareURL} className="Demo__some-network__share-button">
                    <FacebookIcon size={32} round />
                </FacebookShareButton>
                <div className="p4" />
                <EmailShareButton url={shareURL}>
                    <EmailIcon size={32} round />
                </EmailShareButton>
                <div className="p4" />
                <WhatsappShareButton url={shareURL} >
                    <WhatsappIcon size={32} round />
                </WhatsappShareButton>
                <div className="p4" />
                <TelegramShareButton url={shareURL} >
                    <TelegramIcon size={32} round />
                </TelegramShareButton>
                <div className="p4" />
                <RedditShareButton url={shareURL}>
                    <RedditIcon size={32} round />
                </RedditShareButton>
            </div>
        </Card>
    );
}

export default ShareCard;
