import React, { useEffect, useState } from "react";
import { Box, Container, Typography, Card, CardContent, Button, Grid } from "@mui/material";
import { useSignedInUserState } from "../hooks/useAuthState";
import { messageService } from "../services/firebase/messageService";
import { MessageType } from "../models/message";
import { staffService } from "../services/firebase/staffService";

const MessagesPage: React.FC = () => {
  const [messages, setMessages] = useState<MessageType[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<string>("");
  const user = useSignedInUserState().user;

  useEffect(() => {
    const fetchMessages = async () => {
      try {
        if (!user || !user.uid) {
          setError("You must be signed in to view messages.");
          setLoading(false);
          return;
        }
        setError("");
        setLoading(true);
        const fetchedMessages = await messageService.fetchMessagesByUser(user.uid);
        setMessages(fetchedMessages);
      } catch (err) {
        setError("Failed to fetch messages. Please try again later.");
      } finally {
        setLoading(false);
      }
    };

    fetchMessages();
  }, [user?.uid]);

  const handleAcceptInvitation = async (message: MessageType, restaurantId: string) => {
    try {
      if (!user || !user.uid) {
        setError("You must be signed in to accept invitations.");
        return;
      }
      await staffService.acceptStaffInvitation(user.uid, restaurantId, message);
      setMessages(messages.filter((msg) => msg.id !== message.id)); // Remove the message from state
    } catch (err) {
      setError("Failed to accept invitation. Please try again later.");
    }
  };

  return (
    <Box sx={{ minHeight: "100vh", backgroundColor: "#f5f5f5", paddingTop: 4 }}>
      <Container>
        <Typography variant="h4" gutterBottom>
          Messages
        </Typography>

        {loading && <Typography>Loading messages...</Typography>}
        {error && <Typography color="error">{error}</Typography>}

        <Grid container spacing={3}>
          {messages.map((msg) => (
            <Grid item xs={12} sm={6} md={4} key={msg.id}>
              <Card>
                <CardContent>
                  <Typography variant="h6">{msg.title}</Typography>
                  <Typography variant="body2" color="textSecondary" gutterBottom>
                    {msg.description}
                  </Typography>

                  {msg.type === "staff_invitation" && msg.cta === "accept" && msg.cta_target && (
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={() => handleAcceptInvitation(msg, msg.cta_target!)}
                    >
                      Accept Invitation
                    </Button>
                  )}

                  {msg.cta === "open" && msg.cta_target && (
                    <Button variant="outlined" color="primary" onClick={() => window.open(msg.cta_target, "_blank")}>
                      Open
                    </Button>
                  )}
                </CardContent>
              </Card>
            </Grid>
          ))}
        </Grid>
      </Container>
    </Box>
  );
};

export default MessagesPage;
