import React, { useState, useEffect } from "react";
import {
  Card,
  CardContent,
  Typography,
  Button,
  Grid,
  Container,
  Box,
  List,
  ListItem,
  ListItemText,
  IconButton,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { NavLink, useNavigate } from "react-router-dom";
import { useSignedInUserState } from "../hooks/useAuthState";
import { restaurantService } from "../services/firebase/restaurantManagementService";
import { staffService } from "../services/firebase/staffService"; // New service for managing staff
import { StringsService } from "../services/strings/strings";
import { emptyProperty, FullProperty } from "../models/fullProperty";
import AsyncCardMedia from "../shared/asyncCardMedia";
import AsyncString from "../shared/asyncString";
import InviteStaffDialog from "./staffInvitationModal";
import { ProfileType } from "../models/profileType";

type RestaurantProfileDictionary = {
  [restaurantId: string]: ProfileType[]; // Dictionary mapping restaurant IDs to ProfileType
};

const RestaurantStaffManagementPage: React.FC = () => {
  const [restaurants, setRestaurants] = useState<FullProperty[]>([]);
  const [restaurantStaff, setRestaurantStaff] = useState<RestaurantProfileDictionary>({});
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<string>("");
  const user = useSignedInUserState().user;
  const navigate = useNavigate();
  const [dialogOpen, setDialogOpen] = useState<boolean>(false);
  const [selectedRestaurant, setSelectedRestaurant] = useState<FullProperty>(emptyProperty);
  const [confirmRemoveDialog, setConfirmRemoveDialog] = useState<{ open: boolean; staffId?: string; restaurant?: FullProperty }>({
    open: false,
  });

  useEffect(() => {
    const fetchRestaurants = async () => {
      try {
        if (!user || !user.uid) {
          setError(await StringsService.getStringById("error-not-signed-in"));
          setLoading(false);
          return;
        }

        setLoading(true);

        const restaurants = await restaurantService.fetchRestaurantsByOwner(user.uid);
        setRestaurants(restaurants);
        let restaurantsToStaff: RestaurantProfileDictionary = {};
        await Promise.all(
          restaurants.map(async (restaurant: FullProperty) => {
            restaurantsToStaff[restaurant.id] = await restaurantService.fetchStaffByRestaurant(restaurant);
          })
        );
        setRestaurantStaff(restaurantsToStaff);
      } catch (err) {
        setError("Failed to fetch restaurants. Please try again later.");
      } finally {
        setLoading(false);
      }
    };

    fetchRestaurants();
  }, [user?.uid]);

  const handleInviteStaff = (restaurant: FullProperty) => {
    setSelectedRestaurant(restaurant);
    setDialogOpen(true);
  };

  const handleRemoveStaff = async () => {
    const { staffId, restaurant } = confirmRemoveDialog;
    if (!staffId || !restaurant) return;

    try {
      await staffService.removeStaffFromRestaurant(staffId, restaurant);

      const updatedStaff = restaurantStaff[restaurant.id].filter((staff) => staff.id !== staffId);
      setRestaurantStaff((prev) => ({ ...prev, [restaurant.id]: updatedStaff }));
      setConfirmRemoveDialog({ open: false });
    } catch (error) {
      setError("Failed to remove staff member. Please try again later.");
    }
  };

  return (
    <Box
      sx={{
        backgroundColor: "#f5f5f5",
        minHeight: "100vh",
        display: "flex",
        alignItems: "top",
        justifyContent: "center",
      }}
    >
      <Container>
        <Box textAlign="center" mt={4} mb={4}>
          <Typography variant="h4">Manage Restaurant Staff</Typography>
        </Box>
        <div style={{ padding: "16px" }} />
        <Grid container spacing={3}>
          {restaurants.map((restaurant) => (
            <Grid item xs={12} sm={6} md={4} key={restaurant.id}>
              <Card sx={{ maxWidth: 345, margin: "auto" }}>
                <AsyncCardMedia
                  component="img"
                  height="140"
                  imageId={restaurant.id + "-thumbnail"}
                  alt="Restaurant Thumbnail"
                />
                <CardContent>
                  <Typography variant="h6" component="div" gutterBottom>
                    {restaurant.name}
                  </Typography>
                  <Typography variant="body2" color="text.secondary">
                    <AsyncString stringId={restaurant.id + "-short-restaurant-summary"} />
                  </Typography>
                  <Typography variant="subtitle1" mt={2}>
                    Staff Members:
                  </Typography>
                  <List>
                    {restaurantStaff[restaurant.id]?.length > 0 ? (
                      restaurantStaff[restaurant.id].map((staff) => (
                        <ListItem key={staff.id}>
                          <ListItemText primary={staff.name} secondary={staff.email} />
                          <IconButton
                            edge="end"
                            aria-label="remove"
                            onClick={() =>
                              setConfirmRemoveDialog({
                                open: true,
                                staffId: staff.id,
                                restaurant: restaurant,
                              })
                            }
                          >
                            <CloseIcon />
                          </IconButton>
                        </ListItem>
                      ))
                    ) : (
                      <Typography variant="body2" color="text.secondary">
                        No staff members added yet.
                      </Typography>
                    )}
                  </List>
                </CardContent>
                <Box textAlign="center" pb={2}>
                  <Button variant="outlined" color="primary" onClick={() => handleInviteStaff(restaurant)}>
                    Invite Staff
                  </Button>
                </Box>
              </Card>
            </Grid>
          ))}
        </Grid>
        {user && (
          <InviteStaffDialog
            sentBy={user.uid}
            open={dialogOpen}
            onClose={() => setDialogOpen(false)}
            restaurant={selectedRestaurant}
          />
        )}
        <Dialog
          open={confirmRemoveDialog.open}
          onClose={() => setConfirmRemoveDialog({ open: false })}
        >
          <DialogTitle>Confirm Removal</DialogTitle>
          <DialogContent>
            <DialogContentText>
              Are you sure you want to remove this staff member?
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => setConfirmRemoveDialog({ open: false })} color="primary">
              Cancel
            </Button>
            <Button onClick={handleRemoveStaff} color="secondary" autoFocus>
              Remove
            </Button>
          </DialogActions>
        </Dialog>
      </Container>
    </Box>
  );
};

export default RestaurantStaffManagementPage;
