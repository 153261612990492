// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { connectAuthEmulator, getAuth } from "firebase/auth";
import { connectFirestoreEmulator, getFirestore } from "firebase/firestore";
import { connectStorageEmulator, getStorage } from "firebase/storage";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyC92utOiarrukBG1056mfqqbSY58TUfEbQ",
  authDomain: "good-as-new-76351.firebaseapp.com",
  projectId: "good-as-new-76351",
  storageBucket: "good-as-new-76351.firebasestorage.app",
  messagingSenderId: "416609182293",
  appId: "1:416609182293:web:21788978f3516ba552eadc",
  measurementId: "G-XSL5NC3Z5S"
};

// Initialize Firebase
export const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);
export const auth = getAuth(app);
export const firestore = getFirestore(app);
export const storage = getStorage();



export const functionsBaseUrl = process.env.REACT_APP_FIREBASE_ENV === 'development'
    ? 'http://127.0.0.1:5001/good-as-new-76351/us-central1'
    : 'https://us-central1-your-project-id.cloudfunctions.net/api/yourFunction';


(async () => {
    try {
        // Use 127.0.0.1 instead of localhost
        const authEmulatorUrl = "http://127.0.0.1:9099";
        await fetch(authEmulatorUrl);
        connectAuthEmulator(auth, authEmulatorUrl, {
            disableWarnings: true,
        });
        console.info("🎮 Firebase Auth: emulated");
    } catch (e) {
        console.info("🔥 Firebase Auth: not emulated");
    }
})();

(async () => {
    try {
        // Use 127.0.0.1 instead of localhost
        const host = "127.0.0.1";
        const port = 8080;
        await fetch("http://" + host + ":" + port);
        connectFirestoreEmulator(firestore, host, port);
        console.info("🎮 Firebase Firestore: emulated");
    } catch (e) {
        console.info("🔥 Firebase Firestore: not emulated");
    }
})();

(async () => {
    try {
        // Use 127.0.0.1 instead of localhost
        const host = "127.0.0.1";
        const port = 9199;
        await fetch("http://" + host + ":" + port);
        connectStorageEmulator(storage, host, port);
        console.info("🎮 Firebase Storage: emulated");
    } catch (e) {
        console.info("🔥 Firebase Storage: not emulated");
    }
})();
