import React, { useState, useEffect } from "react";
import {
  Card,
  CardContent,
  Typography,
  Button,
  Grid,
  Container,
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  Checkbox,
  FormControlLabel,
  CircularProgress,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  CardMedia,
  IconButton,
} from "@mui/material";
import { useParams } from "react-router-dom";
import { restaurantService } from "../services/firebase/restaurantManagementService";
import { SaleItem, DietryRequirement } from "../models/saleItem";
import { DropzoneState, useDropzone } from "react-dropzone";
import { Close } from "@mui/icons-material";
import { ImageService } from "../services/images/images";
import { StringsService } from "../services/strings/strings";
import { v4 as uuidv4 } from "uuid";
import AsyncString from "../shared/asyncString";
import AsyncCardMedia from "../shared/asyncCardMedia";


interface SaleItemDetailsType {
  "thumbnail": string; // "restaurant-" + props.listing.id + "-sale-item-" + row.id + "-thumbnail"
  "name": string; // "restaurant-" + props.listing.id + "-saleItem-" + row.id + "-sale-item-name"
  "description": string; // "restaurant-" + props.listing.id + "-saleItem-" + row.id + "-sale-item-description"
}

const MaintainSpecificRestaurantPage: React.FC = () => {
  const { id } = useParams<{ id: string }>();
  const [saleItems, setSaleItems] = useState<SaleItem[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<string>("");
  const [dialogOpen, setDialogOpen] = useState<boolean>(false);
  const [editingItem, setEditingItem] = useState<boolean>(false);
  const [currentItem, setCurrentItem] = useState<SaleItem | null>(null);
  const [thumbnailImage, setThumbnailImage] = useState<File | null>(null);
  const [oldThumbnailImage, setOldThumbnailImage] = useState<string | null>(null);
  const [saleItemDetails, setSaleItemDetails] = useState<SaleItemDetailsType>({
    "thumbnail": "",
    "name": "",
    "description": ""
  });

  useEffect(() => {
    const fetchSaleItems = async () => {
      try {
        setLoading(true);
        const data = await restaurantService.fetchSaleItems(id!);
        setSaleItems(data);
      } catch (err) {
        setError("Failed to fetch sale items.");
      } finally {
        setLoading(false);
      }
    };

    fetchSaleItems();
  }, [id]);

  const handleOpenDialog = async (item?: SaleItem) => {
    if (item) {
      setLoading(true);
      setEditingItem(true);
      setSaleItemDetails({
        "thumbnail": await StringsService.getStringById(`restaurant-${id}-sale-item-${item.id}-thumbnail`),
        "name": await StringsService.getStringById(`restaurant-${id}-saleItem-${item.id}-sale-item-name`),
        "description": await StringsService.getStringById(`restaurant-${id}-saleItem-${item.id}-sale-item-description`)
      })
      setCurrentItem(item);
      setLoading(false);
    } else {
      setEditingItem(false);
      setCurrentItem({
        id: "",
        dietryRequirements: ["no_requirements"],
        available: 0,
        isActive: true,
        updatedAt: new Date().toISOString(),
        price: 0
      });
      setSaleItemDetails({
        "thumbnail": "",
        "name": "",
        "description": ""
      })
    }
    setDialogOpen(true);
  };

  const handleCloseDialog = () => {
    setDialogOpen(false);
    setCurrentItem(null);
  };

  const handleSaveItem = async () => {
    if (!id || !currentItem) return;
    setLoading(true);
    try {
      if (editingItem) {
        currentItem.price = Math.floor(currentItem.price)
        const newSaleItems = saleItems.map((item) => (item.id === currentItem.id ? currentItem : item));
        await restaurantService.editRestaurant(id, { saleItems: newSaleItems });
        setSaleItems(newSaleItems)
      } else {

        currentItem.id = uuidv4();
        currentItem.price = Math.floor(currentItem.price)
        await restaurantService.addSaleItem(id, currentItem);
        setSaleItems((prev) => [...prev, currentItem]);
      }

      // update thumbnail url
      // This is only needed if we are changing the thumbnail, else we can ignore it.
      if (thumbnailImage) {
        const thumbnailUrl: string = (await ImageService.uploadImagesToStorage([thumbnailImage]))[0];
        await StringsService.updateStringById(
          `restaurant-${id}-sale-item-${currentItem.id}-thumbnail`,
          thumbnailUrl
        )
      }
      await upsertStrings(id, currentItem.id)
      setLoading(false);
      handleCloseDialog();
    } catch (error) {
      setError("Failed to save sale item.");
      setLoading(false);
    }
  };

  const upsertStrings = async (id: string, saleItemId: string) => {
    await StringsService.updateStringById(
      `restaurant-${id}-saleItem-${saleItemId}-sale-item-name`,
      saleItemDetails["name"]
    )
    await StringsService.updateStringById(
      `restaurant-${id}-saleItem-${saleItemId}-sale-item-description`,
      saleItemDetails["description"]
    )
  }

  const handleRemoveItem = async (item: SaleItem) => {
    try {
      await restaurantService.removeSaleItem(id!, item);
      setSaleItems((prev) => prev.filter((i) => i.id !== item.id));
    } catch (error) {
      setError("Failed to remove sale item.");
    }
  };

  const activeThumbnail = (): string | null => {
    if (thumbnailImage) {
      return URL.createObjectURL(thumbnailImage);
    }
    if (oldThumbnailImage) {
      return oldThumbnailImage;
    }
    return null
  }

  const thumbnailProps: DropzoneState = useDropzone({
    accept: { "image/*": [] },
    maxFiles: 1,
    maxSize: 5 * 1024 * 1024, // 5 MB max size
    onDrop: (acceptedFiles: File[]) => {
      if (acceptedFiles.length > 0) {
        const file = acceptedFiles[0];
        if (file.type.startsWith("image/")) {
          setOldThumbnailImage(null);
          setThumbnailImage(file);
        } else {
          console.error("Invalid file type");
        }
      }
    },
    onDropRejected: (fileRejections) => {
      fileRejections.forEach((rejection) => {
        rejection.errors.forEach((error) => {
          console.error(`Error: ${error.message}`);
        });
      });
    },
  });

  return (
    <Box
      sx={{
        backgroundColor: "#f5f5f5",
        minHeight: "100vh",
        display: "flex",
        alignItems: "top",
        justifyContent: "center",
      }}
    >
      <Container>
        <Box textAlign="center" mt={4} mb={4}>
          <Typography variant="h4">Manage Sale Items</Typography>
        </Box>
        {loading ? (
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: "50vh",
            }}
          >
            <CircularProgress />
          </Box>
        ) : (
          <>
            <Button
              variant="contained"
              color="primary"
              onClick={() => handleOpenDialog()}
              sx={{ mb: 2 }}
            >
              Add New Item
            </Button>
            {error && <Typography color="error">{error}</Typography>}
            <Grid container spacing={3}>
              {saleItems.map((item) => (
                <Grid item xs={12} sm={6} md={4} key={item.id}>
                  <Card
                    sx={{ maxWidth: 345, margin: "auto", cursor: "pointer" }}
                    onClick={() => handleOpenDialog(item)}
                  >
                    <CardContent>
                      <AsyncCardMedia
                        component="img"
                        sx={{ height: 200 }}
                        imageId={"restaurant-" + id + "-sale-item-" + item.id + "-thumbnail"}
                        alt="Live from space album cover"
                      />

                      <Typography variant="h6" gutterBottom>
                        <AsyncString stringId={"restaurant-" + id + "-saleItem-" + item.id + "-sale-item-name"} />
                      </Typography>
                      <Typography>
                        <AsyncString stringId={"restaurant-" + id + "-saleItem-" + item.id + "-sale-item-description"} />
                      </Typography>
                      <Typography>Available: {item.available}</Typography>
                      <Typography>
                        Status: {item.isActive ? "Active" : "Inactive"}
                      </Typography>
                      <Typography>
                        Dietary: {item.dietryRequirements.join(", ")}
                      </Typography>
                      <Typography variant="caption">ID: {item.id}</Typography>
                      <Button
                        variant="outlined"
                        color="error"
                        onClick={(e) => {
                          e.stopPropagation();
                          handleRemoveItem(item);
                        }}
                        sx={{ mt: 1 }}
                      >
                        Remove
                      </Button>
                    </CardContent>
                  </Card>
                </Grid>
              ))}
            </Grid>
          </>
        )}
        {currentItem && (
          <Dialog open={dialogOpen} onClose={handleCloseDialog}>
            <DialogTitle>
              {editingItem ? "Edit Sale Item" : "Add New Sale Item"}
            </DialogTitle>
            <DialogContent>
              <TextField
                fullWidth
                label="Name"
                value={saleItemDetails.name}
                onChange={(e) =>
                  setSaleItemDetails((prev) => ({
                    ...prev,
                    name: e.target.value,
                  }))
                }
                margin="normal"
              />
              <TextField
                fullWidth
                label="Description"
                value={saleItemDetails.description}
                onChange={(e) =>
                  setSaleItemDetails((prev) => ({
                    ...prev,
                    description: e.target.value,
                  }))
                }
                margin="normal"
                multiline
                rows={3}
              />
              <TextField
                fullWidth
                label="Available Quantity"
                type="number"
                value={currentItem.available}
                onChange={(e) =>
                  setCurrentItem((prev) => ({
                    ...prev!,
                    available: parseInt(e.target.value, 10),
                  }))
                }
                margin="normal"
              />
              <TextField
                fullWidth
                label="Price In Rand"
                type="number"
                value={currentItem.price / 100}
                onChange={(e) =>
                  setCurrentItem((prev) => ({
                    ...prev!,
                    price: parseFloat(e.target.value) * 100,
                  }))
                }
                margin="normal"
              />
              <FormControlLabel
                control={
                  <Checkbox
                    checked={currentItem.isActive}
                    onChange={(e) =>
                      setCurrentItem((prev) => ({
                        ...prev!,
                        isActive: e.target.checked,
                      }))
                    }
                  />
                }
                label="Is Active"
              /> <Card sx={{ width: "100%" }}>
                <CardContent>
                  <Typography variant="h5" gutterBottom>
                    Set your Sale Item Thumbnail
                  </Typography>

                  {/* Drag-and-Drop Area */}
                  <Box
                    {...thumbnailProps.getRootProps()}
                    sx={{
                      border: "2px dashed #cccccc",
                      borderRadius: "5px",
                      padding: 2,
                      marginTop: 2,
                      textAlign: "center",
                      backgroundColor: "#fafafa",
                    }}
                  >
                    <input {...thumbnailProps.getInputProps()} />
                    <Typography variant="body1">
                      Drag and drop your thumbnail here, or click to select files
                    </Typography>
                  </Box>

                  {/* Uploaded Images Preview */}
                  <Box sx={{ marginTop: 2 }}>
                    {activeThumbnail() && (
                      <Box
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "space-between",
                          padding: 1,
                          border: "1px solid #cccccc",
                          borderRadius: "5px",
                          marginBottom: 1,
                          backgroundColor: "#ffffff",
                        }}
                      >
                        <CardMedia
                          component="img"
                          height="140"
                          image={activeThumbnail()!}
                          alt="Sale Item Thumbnail"
                        />

                        {/* Remove button */}
                        <IconButton
                          aria-label="Remove file"
                          size="small"
                          onClick={() => {
                            setThumbnailImage(null);
                            setOldThumbnailImage(null);
                          }}
                          sx={{
                            color: "#ff4d4d",
                            "&:hover": { color: "#ff0000" },
                          }}
                        >
                          <Close />
                        </IconButton>
                      </Box>
                    )}
                  </Box>
                </CardContent>
              </Card>
              <FormControl fullWidth margin="normal">
                <InputLabel>Dietary Requirements</InputLabel>
                <Select
                  multiple
                  value={currentItem.dietryRequirements}
                  onChange={(e) =>
                    setCurrentItem((prev) => ({
                      ...prev!,
                      dietryRequirements: e.target.value as DietryRequirement[],
                    }))
                  }
                  renderValue={(selected) => selected.join(", ")}
                >
                  {[
                    "no_requirements",
                    "halal",
                    "vegan",
                    "vegetarian",
                    "pescetarian",
                  ].map((requirement) => (
                    <MenuItem key={requirement} value={requirement}>
                      {requirement}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </DialogContent>
            <DialogActions>
              <Button onClick={handleCloseDialog}>Cancel</Button>
              <Button onClick={handleSaveItem} color="primary">
                Save
              </Button>
            </DialogActions>
          </Dialog>
        )}
      </Container>
    </Box>
  );
};

export default MaintainSpecificRestaurantPage;
