import React, { useEffect, useState } from 'react';
import { Button, CardMedia, Paper, Table, TableBody, TableCell, TableContainer, TableRow, Typography } from '@mui/material';
import { StringsService } from '../../services/strings/strings';
import { FullProperty } from '../../models/fullProperty';
import { DietryRequirement, SaleItem } from '../../models/saleItem';
import AsyncString from '../../shared/asyncString';
import AsyncCardMedia from '../../shared/asyncCardMedia';
import BuyButton from '../buyButton';


interface IProps {
  listing: FullProperty
}

function OverviewCard(props: IProps) {
  // TODO: Replace with food dietry requirements icons
  // const rows = [
  //     ["Listing Number", props.listing.id],
  //     ["Type of Property", StringsService.propertyTypeAsString(props.listing)],
  //     ["Available for ", StringsService.transactionTypeAsString(props.listing)],
  //     ["Price", await StringsService.getStringById(props.listing.id + "-property-price-zar-displayable")],
  //     ["Erf Size", props.listing.plotSize + " " + await StringsService.getStringById("meters_squared")],
  //     ["Floor Size", props.listing.houseSize + " " + await StringsService.getStringById("meters_squared")],
  //     ["Has a flatlet", StringsService.boolToYesOrNo(props.listing.hasFlatlet)],
  //     ["Has a garden", StringsService.boolToYesOrNo(props.listing.hasGarden)],
  //     ["Has a pool", StringsService.boolToYesOrNo(props.listing.hasPool)],
  //     ["Is a new build", StringsService.boolToYesOrNo(props.listing.isNewBuild)],
  //     ["Is on auction", StringsService.boolToYesOrNo(props.listing.isOnAuction)],
  //     ["Is pet friendly", StringsService.boolToYesOrNo(props.listing.isPetFriendly)],
  //     ["For retierees", StringsService.boolToYesOrNo(props.listing.isRetiermentVillage)]
  // ];
  const rows: SaleItem[] = props.listing.saleItems.filter((s: SaleItem) => s.isActive);

  return (<TableContainer component={Paper}>
    <Table aria-label="simple table" sx={{ backgroundColor: "#F0FFFF" }}>
      <TableBody>
        {rows.map((row) => (
          <TableRow
            key={"restaurant-" + props.listing.id + "-saleItem-" + row.id as string}
            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
          >

            <TableCell component="th" scope="row">
              <AsyncCardMedia
                component="img"
                sx={{ height: 200 }}
                imageId={"restaurant-" + props.listing.id + "-sale-item-" + row.id + "-thumbnail"}
                alt="Live from space album cover"
              />
            </TableCell>
            <TableCell component="th" scope="row">
              <Typography variant="h6" gutterBottom>
                <AsyncString stringId={"restaurant-" + props.listing.id + "-saleItem-" + row.id + "-sale-item-name"} />
              </Typography>
              <br />
              <AsyncString stringId={"restaurant-" + props.listing.id + "-saleItem-" + row.id + "-sale-item-description"} />
              <br />
              {
                row.dietryRequirements.map((requirement: DietryRequirement) => (
                  <Typography variant="caption" gutterBottom sx={{ display: 'block' }}>
                    {requirement}
                  </Typography>
                ))
              }
            </TableCell>
            <TableCell align="left">
                <BuyButton
                  saleItem={row}
                  restaurantId={props.listing.id} />
            </TableCell>
          </TableRow>
        ))}
      </TableBody>
    </Table>
  </TableContainer>
  );
}

export default OverviewCard;
