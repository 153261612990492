import { auth, functionsBaseUrl } from "../../firebase";
import { CartServiceAddItemRequestType, CartServiceRemoveItemRequestType, PollPurchaseStatusRequestType, PurchaseCartRequestType } from "../../models/CartServiceType";
import { CartStatus, CartType } from "../../models/CartType";
import { SaleItem } from "../../models/saleItem";
import { yocoCheckoutsResponse } from "../../models/yocoCheckoutRequests";
import { FirestoreService } from "../firebase/firestoreService";

const CARTS_COLLECTION_NAME = "carts";

export const CartService = {
  fetchCart: async (): Promise<CartType | null> => {
    if (!auth.currentUser) {
      return null;
    }
    const customerId: string = auth.currentUser.uid;
    const cart = await FirestoreService.getDocument(CARTS_COLLECTION_NAME, customerId)
    return cart as CartType; // Convert Firestore data to FullProperty instance
  },

  addItemToCart: async (quantity: number, restaurantId: string, saleItem: SaleItem): Promise<CartType | null> => {
    if (!auth.currentUser) {
      return null;
    }
    const idToken: string = await auth.currentUser.getIdToken()
    const body: CartServiceAddItemRequestType = {
      quantity,
      restaurantId,
      saleItemId: saleItem.id
    }

    const response = await fetch(`${functionsBaseUrl}/addItemToCart`, {
      method: 'POST',
      headers: {
        'Authorization': `Bearer ${idToken}`,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(body),
    });
    console.log(response)
    if (response.status === 200) {
      return (await response.json())["cart"] as CartType;
    } 
    if (response.status === 400) {
      throw new Error((await response.json())["error"]);
      
    }
    return null;
  },

  removeItemFromCart: async (restaurantId: string, saleItem: SaleItem): Promise<CartType | null> => {
    if (!auth.currentUser) {
      return null;
    }
    const idToken: string = await auth.currentUser.getIdToken()
    const body: CartServiceRemoveItemRequestType = {
      restaurantId,
      saleItemId: saleItem.id
    }

    const response = await fetch(`${functionsBaseUrl}/removeItemFromCart`, {
      method: 'POST',
      headers: {
        'Authorization': `Bearer ${idToken}`,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(body),
    });
    console.log(response)
    if (response.status === 200) {
      return (await response.json())["cart"] as CartType;
    }
    return null;
  },

  purchaseCart: async (): Promise<yocoCheckoutsResponse | null> => {
    if (!auth.currentUser) {
      return null;
    }
    const idToken: string = await auth.currentUser.getIdToken()
    const body: PurchaseCartRequestType = {}

    const response = await fetch(`${functionsBaseUrl}/purchaseCart`, {
      method: 'POST',
      headers: {
        'Authorization': `Bearer ${idToken}`,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(body),
    });
    console.log(response)
    if (response.status === 200) {
      const yocoResponse = (await response.json())["yocoResponse"] as yocoCheckoutsResponse;
      return yocoResponse;
    }
    return null;
  },

  pollPurchaseStatus: async (restaurantId: string, saleItem: SaleItem): Promise<CartStatus | null> => {
    if (!auth.currentUser) {
      return null;
    }
    const idToken: string = await auth.currentUser.getIdToken()
    const body: PollPurchaseStatusRequestType = {}

    const response = await fetch(`${functionsBaseUrl}/pollPurchaseStatus`, {
      method: 'POST',
      headers: {
        'Authorization': `Bearer ${idToken}`,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(body),
    });
    console.log(response)
    if (response.status === 200) {
      const status = (await response.json());
      return status["status"];
    }
    return null;
  },
};




// export const addItemToCart = onRequest(async (data, context) => {
//   await CartService.addItemToCart(data, context, db);
// });

// export const removeItemFromCart = onRequest(async (data, context) => {
//   await CartService.removeItemFromCart(data, context, db);
// });

// export const purchaseCart = onRequest(async (data, context) => {
//   await CartService.purchaseCart(data, context, db);
// });

// export const pollPurchaseStatus = onRequest(async (data, context) => {
//   await CartService.pollPurchaseStatus(data, context, db);
// });

