import React, { createContext, useContext, useState, useEffect } from "react";
import { CartType } from "../../models/CartType";
import { CartService } from "../../services/functions/cartService";
import { SaleItem } from "../../models/saleItem";
import { onAuthStateChanged } from "firebase/auth";
import { auth } from "../../firebase";
import { yocoCheckoutsResponse } from "../../models/yocoCheckoutRequests";
import { useNavigate } from "react-router-dom";

// Define CartContext outside the component
const CartContext = createContext<CartContextType | null>(null);

interface CartContextType {
  cart: CartType | null;
  loading: boolean;
  fetchCart: () => Promise<void>;
  addItem: (quantity: number, restaurantId: string, saleItem: SaleItem) => Promise<void>;
  removeItem: (restaurantId: string, saleItem: SaleItem) => Promise<void>;
  checkout: () => Promise<void>
}

interface CartProviderProps {
  children: React.ReactNode;
}

export const CartProvider = ({ children }: CartProviderProps) => {
  const [cart, setCart] = useState<CartType | null>(null);
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();

  const fetchCart = async () => {
    setLoading(true);
    try {
      const fetchedCart = await CartService.fetchCart();
      setCart(fetchedCart);
    } catch (error) {
      console.error("Failed to fetch cart:", error);
    } finally {
      setLoading(false);
    }
  };

  const addItem = async (quantity: number, restaurantId: string, saleItem: SaleItem) => {
    try {
      const cart = await CartService.addItemToCart(quantity, restaurantId, saleItem);      
      setCart(cart);  // Refresh cart after updating
    } catch (error) {
      console.error("Failed to add item:", error);
    }
  };

  const removeItem = async (restaurantId: string, saleItem: SaleItem) => {
    try {
      const cart = await CartService.removeItemFromCart(restaurantId, saleItem);
      setCart(cart);  // Refresh cart after updating
    } catch (error) {
      console.error("Failed to remove item:", error);
    }
  };


  const checkout = async () => {
    try {
      const cart: yocoCheckoutsResponse | null = await CartService.purchaseCart();
      console.log(cart)
      if (cart) {
        window.location.href = cart.redirectUrl;
      }
    } catch (error) {
      console.error("Failed to remove item:", error);
    }
  };


  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      fetchCart()
    });

    return () => unsubscribe(); // Clean up the listener on unmount
  }, [auth]);

    return <CartContext.Provider value={{ cart, loading, fetchCart, addItem, removeItem, checkout }}>
      {children}
    </CartContext.Provider>
};

export const useCart = (): CartContextType => {
  const context = useContext(CartContext);
  if (!context) {
    throw new Error("useCart must be used within a CartProvider");
  }
  return context;
};
