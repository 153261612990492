import { collection, doc, getDoc, setDoc, getDocs } from "firebase/firestore";
import { firestore } from "../../firebase";

const COLLECTION_NAME = "string-collection";

const getStringById = async (string_id: string): Promise<string> => {
  try {
    const docRef = doc(firestore, COLLECTION_NAME, string_id);
    const docSnap = await getDoc(docRef);
    if (docSnap.exists()) {
      return docSnap.data().value || "";
    } else {
      console.log("string ID not found: " + string_id);
      return "";
    }
  } catch (error) {
    console.error("Error fetching string by ID:", error);
    return "";
  }
};

const updateStringById = async (string_id: string, value: string): Promise<void> => {
  try {
    const docRef = doc(firestore, COLLECTION_NAME, string_id);
    await setDoc(docRef, { value }, { merge: true });
  } catch (error) {
    console.error("Error updating string by ID:", error);
  }
};

const getAllStrings = async (): Promise<Map<string, string>> => {
  try {
    const querySnapshot = await getDocs(collection(firestore, COLLECTION_NAME));
    const stringsMap = new Map<string, string>();
    querySnapshot.forEach((doc) => {
      stringsMap.set(doc.id, doc.data().value);
    });
    return stringsMap;
  } catch (error) {
    console.error("Error fetching all strings:", error);
    return new Map();
  }
};

const StringsService = {
  getStringById,
  updateStringById,
  getAllStrings,
};

export { StringsService };
