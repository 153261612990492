import React, { useEffect, useState } from "react";
import { StringsService } from "../services/strings/strings";

interface AsyncStringProps {
  stringId: string;
}

const AsyncString: React.FC<AsyncStringProps> = ({ stringId }) => {
  const [text, setText] = useState<string>("");

  useEffect(() => {
    const fetchString = async () => {
      const result = await StringsService.getStringById(stringId);
      setText(result || ""); // Default to an empty string if not found
    };

    fetchString();
  }, [stringId]);

  return <>{text}</>; // Render the string once loaded
};

export default AsyncString;
