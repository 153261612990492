
import { useSignedInUserState } from "../hooks/useAuthState";


import React from "react";
import Drawer from "@mui/material/Drawer";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import Divider from "@mui/material/Divider";
import { Theme, useTheme } from "@mui/material/styles";
import { Box, Button, useMediaQuery } from "@mui/material";
import { AuthButton } from "../shared/authButton";
import { useNavigate } from "react-router-dom";

interface SideDrawerProps {
    isOpen: boolean;
    onClose: () => void;
}

const SideDrawer: React.FC<SideDrawerProps> = ({ isOpen, onClose }) => {
    const userProfile = useSignedInUserState().profile;
    const theme: Theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
    const navigate = useNavigate();

    const getMenuItems = () => {
        const baseItems = [
            { name: "Search Food", action: () => navigate("/search") },
            { name: "Order History", action: () => console.log("Order History") },
            { name: "Manage Profile", action: () => navigate("/profile") },
            { name: "Messages", action: () => navigate("/messages") },
        ];

        if (userProfile?.profileType === "staff") {
            return [...baseItems, { name: "Maintain Restaurants", action: () => navigate("/maintainRestaurant") }];
        }

        if (userProfile?.profileType === "owner") {
            return [
                ...baseItems,
                { name: "Maintain Staff", action: () => navigate("/restaurantStaffManagement") },
                { name: "Maintain Restaurants", action: () => navigate("/maintainRestaurant") },
                { name: "Manage Restaurant", action: () => navigate("/restaurantManagement") }, // Updated name
                { name: "Manage Payments", action: () => console.log("Manage Payments") },
            ];
        }

        return baseItems;
    };

    const menuItems = getMenuItems();

    return (
        <Drawer
            anchor="left"
            open={isOpen}
            onClose={onClose}
            variant="temporary"
            PaperProps={{
                sx: {
                    width: isMobile ? "100%" : 300,
                },
            }}
        >
            {/* Logo Section */}
            <Box
                sx={{
                    backgroundColor: "lightgreen",
                    padding: 2,
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                }}
            >
                <img
                    src="/src_assets/659bf227c489a49f48e619d0_Test.png"
                    alt="Logo"
                    style={{ height: "50px" }}
                />
            </Box>

            {/* Menu Items */}
            <List>
                {menuItems.map((item, index) => (
                    <ListItem button key={index} onClick={item.action}>
                        <ListItemText primary={item.name} />
                    </ListItem>
                ))}
                <Divider />
            </List>

            {/* Sign-Out Button at the Bottom */}
            <Box
                sx={{
                    marginTop: "auto",
                    padding: 2,
                    backgroundColor: "#ffd6c7",
                }}
            >
                <AuthButton
                    fullWidth
                    sx={{
                        color: "#ffd6c7"
                    }} />
            </Box>
        </Drawer>
    );
};

export default SideDrawer;
