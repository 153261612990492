import { Button, Typography } from "@mui/material";

interface INavButtonProps {
    href: string;
    text: string;
}

export function NavButton(props: INavButtonProps) {
    return (
        <Button
            href={props.href}
            variant="contained"
            color="primary"
            sx={{ margin: "0 8px" }}
        >
            <Typography variant="button" component="h1" sx={{ color: "white" }}>
                {props.text}
            </Typography>
        </Button>
    );
}