import React, { useEffect, useState } from "react";
import {
    Box,
    TextField,
    Typography,
    Button,
    FormControlLabel,
    Card,
    CardContent,
    CircularProgress,
    Switch,
    CardMedia,
} from "@mui/material";
import { queryNominatim } from "../services/location/nominatim"; // Import your nominatim query function
import { emptyProperty, FullProperty } from "../models/fullProperty";
import { StringsService } from "../services/strings/strings";
import { restaurantService } from "../services/firebase/restaurantManagementService";
import { useSignedInUserState } from "../hooks/useAuthState";
import { useNavigate, useParams } from "react-router-dom";
import { DropzoneState, useDropzone } from "react-dropzone";
import { ImageService } from "../services/images/images";
import { Close } from "@mui/icons-material";
import IconButton from "@mui/material/IconButton";
import { ImageGalleryType } from "../models/imageGallery";

interface RestaurantDetails {
    "thumbnail": string; // this is a link to an image, This will need to be displayed on the page when a customer is configuring the experiment.
    "restaurant-name": string;
    "restaurant-surprise-box-price-zar-displayable": string;
    "short-restaurant-summary": string;
    "full-restaurant-summary": string;
}

export const CreateRestaurantPage: React.FC = () => {
    const { id } = useParams();
    const user = useSignedInUserState().user;
    const [restaurant, setRestaurant] = useState<FullProperty>(emptyProperty);
    const [addressInput, setAddressInput] = useState<string>("");
    const [loadingLocation, setLoadingLocation] = useState<boolean>(false);
    const [loading, setLoading] = useState<boolean>(false);
    const [errorMessage, setErrorMessage] = useState<string | null>(null);
    const [successMessage, setSuccessMessage] = useState<string | null>(null);
    // New state for restaurant details
    const [restaurantDetails, setRestaurantDetails] = useState<RestaurantDetails>({
        "thumbnail": "https://upload.wikimedia.org/wikipedia/en/1/1e/Steers_Logo.jpg",
        "restaurant-name": "Steers",
        "restaurant-surprise-box-price-zar-displayable": "R20 per surprise",
        "short-restaurant-summary": "Steers the home of flame grilled burgers",
        "full-restaurant-summary": "Steers the home of flame grilled burgers\n We offer full on burgers and sometimes we have extra for you"
    });
    const navigate = useNavigate();
    const [uploadedImages, setUploadedImages] = useState<File[]>([]);
    const [oldGalleryImages, setOldGalleryImages] = useState<string[]>([]); // URLs of previously uploaded images
    const [thumbnailImage, setThumbnailImage] = useState<File | null>(null);
    const [oldThumbnailImage, setOldThumbnailImage] = useState<string | null>(null);


    useEffect(() => {
        const fetchStrings = async () => {
            if (id) {
                try {
                    setRestaurant(await restaurantService.fetchRestaurantById(id))
                    setRestaurantDetails({
                        "thumbnail": await StringsService.getStringById(`${id}-thumbnail`),
                        "restaurant-name": await StringsService.getStringById(`${id}-restaurant-name`),
                        "restaurant-surprise-box-price-zar-displayable": await StringsService.getStringById(`${id}-restaurant-surprise-box-price-zar-displayable`),
                        "short-restaurant-summary": await StringsService.getStringById(`${id}-short-restaurant-summary`),
                        "full-restaurant-summary": await StringsService.getStringById(`${id}-full-restaurant-summary`)
                    })

                    const imageUrls = (await ImageService.getGalleryImages(id)).map(img => img.original);
                    setOldGalleryImages(imageUrls); // Populate oldImages with URLs
                    const oldThumb = await StringsService.getStringById(`${id}-thumbnail`);
                    if (oldThumb != "") {
                        setOldThumbnailImage(oldThumb)
                    }

                } catch (error) {
                    console.error("Error", error);
                }
            }
        };

        fetchStrings();
    }, [id]);

    const handleRemoveImage = (index: number) => {
        setUploadedImages((prev) => prev.filter((_, i) => i !== index));
    };

    const { getRootProps, getInputProps } = useDropzone({
        accept: { "image/*": [] },
        maxSize: 5 * 1024 * 1024, // Maximum file size (e.g., 5 MB)
        onDrop: (acceptedFiles) => {
            if (acceptedFiles.length > 0) {
                let supportedFiles: File[] = [];
                acceptedFiles.map((file: File) => {
                    if (file.type.startsWith("image/")) {
                        // Proceed with the file processing
                        supportedFiles.push(file);
                    } else {
                        console.error("Invalid file type");
                    }
                });
                setUploadedImages((prev) => [...prev, ...supportedFiles]);
            }
        },
        onDropRejected: (fileRejections) => {
            // Handle errors for rejected files
            fileRejections.forEach((rejection) => {
                rejection.errors.forEach((error) => {
                    console.error(`Error: ${error.message}`);
                });
            });
        },
    });

    const thumbnailProps: DropzoneState = useDropzone({
        accept: { "image/*": [] },
        maxFiles: 1,
        maxSize: 5 * 1024 * 1024, // Maximum file size (e.g., 5 MB)
        onDrop: (acceptedFiles: File[]) => {
            if (acceptedFiles.length > 0) {
                const file = acceptedFiles[0];
                if (file.type.startsWith("image/")) {
                    // Proceed with the file processing
                    setOldThumbnailImage(null)
                    setThumbnailImage(file);
                } else {
                    console.error("Invalid file type");
                }
            }
        },
        onDropRejected: (fileRejections) => {
            // Handle errors for rejected files
            fileRejections.forEach((rejection) => {
                rejection.errors.forEach((error) => {
                    console.error(`Error: ${error.message}`);
                });
            });
        },
    });

    const handleInputChange = (field: keyof FullProperty, value: any) => {
        setRestaurant((prev) => ({
            ...prev,
            [field]: value,
        }));
    };

    const handleRestaurantDetailsChange = (key: keyof RestaurantDetails, value: string) => {
        setRestaurantDetails((prev) => ({
            ...prev,
            [key]: value,
        }));
    };

    const handleLocationQuery = async () => {
        if (!addressInput || !addressInput.trim()) return;

        setLoadingLocation(true);
        try {
            const response = await queryNominatim(addressInput.trim());
            const data = await response.json();

            if (data && data.length > 0) {
                setRestaurant({
                    ...restaurant,
                    location: data[0],
                });
                setSuccessMessage(await StringsService.getStringById("success-address-fetch"));
            } else {
                setErrorMessage(await StringsService.getStringById("error-address-not-found"));
            }
        } catch (error) {
            setErrorMessage("error-address-failure");
        } finally {
            setLoadingLocation(false);
        }
    };

    const handleSubmit = async () => {
        if (!user || !user.uid) {
            setErrorMessage(await StringsService.getStringById("error-not-signed-in"));
            return;
        }
        setLoading(true);
        setErrorMessage("");
        try {
            restaurant.owner = user?.uid;
            let restaurantId = id;
            if (restaurantId) {
                restaurant.id = restaurantId;
                await restaurantService.editRestaurant(restaurantId, restaurant);
            } else {
                restaurantId = await restaurantService.createRestaurant(restaurant);
            }
            // update thumbnail url
            // This is only needed if we are changing the thumbnail, else we can ignore it.
            if (thumbnailImage) {
                const thumbnailUrl: string = (await ImageService.uploadImagesToStorage([thumbnailImage]))[0];
                await StringsService.updateStringById(
                    `${restaurantId}-thumbnail`,
                    thumbnailUrl
                )
            }
            await upsertStrings(restaurantId);
            await handleImageUpload(restaurantId);
            setSuccessMessage("Restaurant created successfully!");
            // setRestaurant({ ...emptyProperty });
            // navigate("/restaurantManagement")
        } catch (err) {
            setErrorMessage("Failed to create restaurant. Please try again.");
        } finally {
            setLoading(false);
        }
    };

    const upsertStrings = async (id: string) => {
        await StringsService.updateStringById(
            `${id}-restaurant-name`,
            restaurantDetails["restaurant-name"]
        )
        await StringsService.updateStringById(
            `${id}-restaurant-surprise-box-price-zar-displayable`,
            restaurantDetails["restaurant-surprise-box-price-zar-displayable"]
        )
        await StringsService.updateStringById(
            `${id}-short-restaurant-summary`,
            restaurantDetails["short-restaurant-summary"]
        )
        await StringsService.updateStringById(
            `${id}-full-restaurant-summary`,
            restaurantDetails["full-restaurant-summary"]
        )
    }

    const handleImageUpload = async (propertyId: string) => {
        if (uploadedImages.length === 0) return;
        const uploadedUrls = await ImageService.uploadImagesToStorage(uploadedImages);
        console.log("Uploaded URLs:", uploadedUrls);

        // Merge old and new URLs
        const allImageUrls: ImageGalleryType[] = [
            ...oldGalleryImages.map(s => ({
                original: s,
                thumbnail: s,
            })),
            ...uploadedUrls.map((url) => ({
                original: url,
                thumbnail: url,
            }))];
        await ImageService.setGalleryImages(propertyId, allImageUrls);

        console.log("Gallery images updated!");
    };

    const activeThumbnail = (): string | null => {
        if (thumbnailImage) {
            return URL.createObjectURL(thumbnailImage);
        }
        if (oldThumbnailImage) {
            return oldThumbnailImage;
        }
        return null
    }


    if (loading) {
        return (
            <Box
                sx={{
                    backgroundColor: "#f5f5f5",
                    minHeight: "100vh",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    padding: 2,
                }}
            >
                <CircularProgress size={60} />
            </Box>
        );
    }

    return (
        <Box
            sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                minHeight: "100vh",
                backgroundColor: "#f5f5f5",
                padding: 2,
            }}
        >
            <Card sx={{ maxWidth: 500, width: "100%" }}>
                <CardContent>
                    <Typography variant="h5" gutterBottom>
                        Create Restaurant
                    </Typography>
                    <Box sx={{ marginBottom: 2 }}>
                        <TextField
                            label="Restaurant Name"
                            fullWidth
                            value={restaurant.name}
                            onChange={(e) => handleInputChange("name", e.target.value)}
                        />
                    </Box>
                    <Box sx={{ marginBottom: 2 }}>
                        <TextField
                            label="Franchise"
                            fullWidth
                            value={restaurant.franchise}
                            onChange={(e) => handleInputChange("franchise", e.target.value)}
                        />
                    </Box>
                    <Box sx={{ marginBottom: 2 }}>
                        <TextField
                            label="Search Address"
                            fullWidth
                            value={addressInput}
                            onChange={(e) => setAddressInput(e.target.value)}
                            helperText="Enter a location and click 'Search Address'"
                        />
                        <Button
                            variant="contained"
                            color="primary"
                            onClick={handleLocationQuery}
                            sx={{
                                marginTop: 2,
                                height: "50px",
                                backgroundColor: "#FFD700",
                                color: "black",
                                "&:hover": { backgroundColor: "#FFC300" },
                            }}>
                            {loadingLocation ? <CircularProgress size={24} /> : "Search Address"}
                        </Button>
                    </Box>
                    {restaurant.location.display_name && (
                        <Typography variant="body2" sx={{ marginBottom: 2 }}>
                            Selected Address: {restaurant.location.display_name}
                        </Typography>
                    )}
                    <Box sx={{ marginBottom: 2 }}>
                        <FormControlLabel
                            control={
                                <Switch
                                    checked={restaurant.isActive}
                                    onChange={(e) => handleInputChange("isActive", e.target.checked)}
                                />
                            }
                            label="Active"
                        />
                    </Box>
                    <Box sx={{ marginBottom: 2 }}>
                        <Typography variant="h6" gutterBottom>
                            Configure Restaurant Details
                        </Typography>
                        <Box
                            sx={{
                                display: "flex",
                                justifyContent: "center",
                                padding: 2,
                            }}
                        >
                            <Card sx={{ width: "100%" }}>
                                <CardContent>
                                    <Typography variant="h5" gutterBottom>
                                        Set your restaurant Thumbnail
                                    </Typography>

                                    {/* Drag-and-Drop Area */}
                                    <Box
                                        {...thumbnailProps.getRootProps()}
                                        sx={{
                                            border: "2px dashed #cccccc",
                                            borderRadius: "5px",
                                            padding: 2,
                                            marginTop: 2,
                                            textAlign: "center",
                                            backgroundColor: "#fafafa",
                                        }}
                                    >
                                        <input {...thumbnailProps.getInputProps()} />
                                        <Typography variant="body1">
                                            Drag and drop your thumbnail here, or click to select files
                                        </Typography>
                                    </Box>

                                    {/* Uploaded Images Preview */}
                                    <Box sx={{ marginTop: 2 }}>
                                        {activeThumbnail() &&
                                            <Box
                                                sx={{
                                                    display: "flex",
                                                    alignItems: "center",
                                                    justifyContent: "space-between",
                                                    padding: 1,
                                                    border: "1px solid #cccccc",
                                                    borderRadius: "5px",
                                                    marginBottom: 1,
                                                    backgroundColor: "#ffffff",
                                                }}
                                            >
                                                <CardMedia
                                                    component="img"
                                                    height="140"
                                                    image={activeThumbnail()!}
                                                    alt="Placeholder Alt Image"
                                                />

                                                {/* Remove button */}
                                                <IconButton
                                                    aria-label="Remove file"
                                                    size="small"
                                                    onClick={() => {
                                                        setThumbnailImage(null);
                                                        setOldThumbnailImage(null);
                                                    }}
                                                    sx={{
                                                        color: "#ff4d4d",
                                                        "&:hover": { color: "#ff0000" },
                                                    }}
                                                >
                                                    <Close />
                                                </IconButton>
                                            </Box>
                                        }
                                    </Box>
                                </CardContent>
                            </Card>
                        </Box>
                        <Box sx={{ marginBottom: 2 }}>
                            <TextField
                                label="Restaurant Name"
                                fullWidth
                                value={restaurantDetails["restaurant-name"]}
                                onChange={(e) => handleRestaurantDetailsChange("restaurant-name", e.target.value)}
                            />
                        </Box>
                        <Box sx={{ marginBottom: 2 }}>
                            <TextField
                                label="Surprise Box Price (ZAR)"
                                fullWidth
                                value={restaurantDetails["restaurant-surprise-box-price-zar-displayable"]}
                                onChange={(e) => handleRestaurantDetailsChange("restaurant-surprise-box-price-zar-displayable", e.target.value)}
                            />
                        </Box>
                        <Box sx={{ marginBottom: 2 }}>
                            <TextField
                                label="Short Summary"
                                fullWidth
                                value={restaurantDetails["short-restaurant-summary"]}
                                onChange={(e) => handleRestaurantDetailsChange("short-restaurant-summary", e.target.value)}
                            />
                        </Box>
                        <Box sx={{ marginBottom: 2 }}>
                            <TextField
                                label="Full Summary"
                                fullWidth
                                multiline
                                rows={4}
                                value={restaurantDetails["full-restaurant-summary"]}
                                onChange={(e) => handleRestaurantDetailsChange("full-restaurant-summary", e.target.value)}
                            />
                        </Box>
                    </Box>
                    <Box
                        sx={{
                            display: "flex",
                            justifyContent: "center",
                            padding: 2,
                        }}
                    >
                        <Card sx={{ width: "100%" }}>
                            <CardContent>
                                <Typography variant="h5" gutterBottom>
                                    Add to your gallery
                                </Typography>

                                {/* Drag-and-Drop Area */}
                                <Box
                                    {...getRootProps()}
                                    sx={{
                                        border: "2px dashed #cccccc",
                                        borderRadius: "5px",
                                        padding: 2,
                                        marginTop: 2,
                                        textAlign: "center",
                                        backgroundColor: "#fafafa",
                                    }}
                                >
                                    <input {...getInputProps()} />
                                    <Typography variant="body1">
                                        Drag and drop images here, or click to select files
                                    </Typography>
                                </Box>

                                {/* Uploaded Images Preview */}
                                <Box sx={{ marginTop: 2 }}>
                                    {[...oldGalleryImages, ...uploadedImages.map((file) => URL.createObjectURL(file))].map((src, index) => (
                                        <Box
                                            key={index}
                                            sx={{
                                                display: "flex",
                                                alignItems: "center",
                                                justifyContent: "space-between",
                                                padding: 1,
                                                border: "1px solid #cccccc",
                                                borderRadius: "5px",
                                                marginBottom: 1,
                                                backgroundColor: "#ffffff",
                                            }}
                                        >
                                            {/* Display file name */}
                                            <CardMedia
                                                component="img"
                                                height="140"
                                                image={src}
                                                alt="Placeholder Alt Image"
                                            />
                                            {/* Remove button */}
                                            <IconButton
                                                aria-label="Remove file"
                                                size="small"
                                                onClick={() => handleRemoveImage(index)}
                                                sx={{
                                                    color: "#ff4d4d",
                                                    "&:hover": { color: "#ff0000" },
                                                }}
                                            >
                                                <Close />
                                            </IconButton>
                                        </Box>
                                    ))}
                                </Box>
                            </CardContent>
                        </Card>
                    </Box>
                    <Box sx={{ marginBottom: 2 }}>
                        <Button
                            variant="contained"
                            color="primary"
                            onClick={handleSubmit}
                            sx={{
                                marginTop: 2,
                                height: "50px",
                                backgroundColor: "#FFD700",
                                color: "black",
                                "&:hover": { backgroundColor: "#FFC300" },
                            }}>
                            {
                                id ? (loading ? <CircularProgress size={24} /> : "Save Restaurant") :
                                    (loading ? <CircularProgress size={24} /> : "Create Restaurant")
                            }
                        </Button>
                    </Box>
                    {errorMessage && (
                        <Typography color="error" variant="body2">
                            {errorMessage}
                        </Typography>
                    )}
                </CardContent>
            </Card>
        </Box>
    );
};
