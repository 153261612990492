import React, { useEffect, useState } from "react";
import CardMedia from "@mui/material/CardMedia";
import { StringsService } from "../services/strings/strings";

interface AsyncCardMediaProps {
  imageId: string; // ID to fetch the image URL
  alt: string; // Alt text for the image
  component?: React.ElementType; // Optional: Override the component type
  sx?: object; // Style object for MUI's sx prop
  height?: string
}

const AsyncCardMedia: React.FC<AsyncCardMediaProps> = ({ imageId, alt, component = "img", sx, height }) => {
  const [imageUrl, setImageUrl] = useState<string>("");

  useEffect(() => {
    const fetchImage = async () => {
      const url = await StringsService.getStringById(imageId);
      setImageUrl(url || ""); // Default to an empty string if not found
    };

    fetchImage();
  }, [imageId]);

  if (!imageUrl) {
    return null; // Optionally, render a placeholder or loader while fetching
  }

  return <CardMedia
    component={component}
    image={imageUrl}
    alt={alt}
    sx={sx}
    height={height} />;
};

export default AsyncCardMedia;
