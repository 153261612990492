import React, { useState } from 'react';
import Card from '@mui/material/Card';
import { CardMedia, Typography } from '@mui/material';
import { StringsService } from '../../services/strings/strings';
import AsyncString from '../../shared/asyncString';


interface IProps {
    id: string
}


function DescriptionCard(props: IProps) {
    return (
        <Card sx={{ backgroundColor: "#F0FFFF", justifyContent: "center", display: "flex !important", flexDirection: "column" }}>
            <div className="p8">
                <Typography variant="h5">
                    <AsyncString stringId={props.id + "-restaurant-name"} />
                </Typography>
            </div>
            <div className="p8">
                <Typography variant="body1" sx={{ whiteSpace: "pre-line" }}>
                    <AsyncString stringId={props.id + "-full-restaurant-summary"} />
                </Typography>
            </div>
        </Card>
    );
}

export default DescriptionCard;
