import React, { useState } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  Button,
  Typography,
} from "@mui/material";
import { MessageType } from "../models/message"; // Import your message type
import { userService } from "../services/firebase/userService";
import { messageService } from "../services/firebase/messageService";
import { FullProperty } from "../models/fullProperty";

interface InviteStaffDialogProps {
  open: boolean;
  onClose: () => void;
  restaurant: FullProperty; // Pass the restaurant ID to associate messages with
  sentBy: string;
}

const InviteStaffDialog: React.FC<InviteStaffDialogProps> = ({ open, onClose, restaurant, sentBy }) => {
  const [email, setEmail] = useState<string>("");
  const [error, setError] = useState<string>("");

  const handleSendInvitation = async () => {
    try {
      // Resolve email to UID
      const userId = await userService.getUidByEmail(email);
      if (!userId) {
        setError("User not found with the provided email.");
        return;
      }

      if (restaurant.staff.indexOf(userId) !== -1) {
        setError("User is already a staff member.");
        return;
      }

      // Create the message
      const newMessage: MessageType = {
        title: "Staff Invitation",
        description: `You have been invited to join the restaurant as staff.`,
        cta: "accept",
        cta_target: restaurant.id,
        type: "staff_invitation",
        sent_by: sentBy,
        createdAt: new Date().toISOString(),
        isActive: true,
      };

      // Add message to the collection
      await messageService.sendMessageToUser(userId, newMessage);

      // Close dialog and clear input
      setEmail("");
      onClose();
    } catch (err) {
      setError("Failed to send invitation. Please try again.");
    }
  };

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>Invite Staff Member</DialogTitle>
      <DialogContent>
        <Typography variant="body2" color="error">
          {error}
        </Typography>
        <TextField
          autoFocus
          margin="dense"
          label="User Email"
          type="email"
          fullWidth
          variant="outlined"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="primary">
          Cancel
        </Button>
        <Button onClick={handleSendInvitation} color="primary">
          Send Invitation
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default InviteStaffDialog;
