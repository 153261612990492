import React from "react";
import ShoppingCartIcon from "@mui/icons-material/ShoppingCart";
import { Button } from "@mui/material";
import { useCart } from "../hooks/contexts/cartContext";
import { useNavigate } from "react-router-dom";

const CartPillButton = () => {
  const { cart } = useCart();
  const navigate = useNavigate();

  return (
    <Button
      variant="contained"
      onClick={() => {navigate("/order/confirm")}}
      color="primary"
      style={{
        position: "fixed",
        bottom: "1rem",
        right: "1rem",
        borderRadius: "50px",
        padding: "0.5rem 1.5rem",
        display: "flex",
        alignItems: "center",
      }}
    >
      <ShoppingCartIcon style={{ marginRight: "0.5rem" }} />
      <span>
        R{(cart?.totalAmount || 0) / 100} - {cart?.items.length || 0} items
      </span>
    </Button>
  );
};

export default CartPillButton;
