import { SxProps, Theme } from "@mui/material/styles";
import AsyncNavButton from "./asyncNavButton";
import AsyncString from "./asyncString";
import { useSignedInState } from "../hooks/useAuthState";
import { signOut } from "firebase/auth";
import { auth } from "../firebase";
import { Button, Typography } from "@mui/material";


const handleLogout = async () => {
    await signOut(auth);
    console.log("User logged out");
};

export interface AuthButtonProps {
    fullWidth?: boolean;
    sx?: SxProps<Theme>;
}

export function AuthButton(props: AuthButtonProps) {
    const signedIn = useSignedInState();
    if (signedIn) {
        return (
            <Button
                fullWidth={props.fullWidth}
                onClick={handleLogout}
                variant="contained"
                color="secondary"
                sx={props.sx ? props.sx : { margin: "0 8px" }}
            >
                <Typography variant="button" component="h1" sx={{ color: "white" }}>
                    <AsyncString stringId="sign-out" />
                </Typography>
            </Button>
        );
    } else {
        return (
            <Button
                fullWidth={props.fullWidth}
                href="/login"
                onClick={handleLogout}
                variant="contained"
                color="secondary"
                sx={props.sx ? props.sx : { margin: "0 8px" }}
            >
                <Typography variant="button" component="h1" sx={{ color: "white" }}>
                    <AsyncString stringId="sign-in" />
                </Typography>
            </Button>
        );
    }
}