import React, { useEffect, useState } from "react";
import {
  useLocation
} from "react-router-dom";
import { DistanceSearchItem, SearchListingsQuery } from "../models/search"
import { ListingCard } from "./listingCard"
import "./index.css"
import { Typography } from "@mui/material";
import Paginate, { ReactPaginateProps } from 'react-paginate'
import classnames from 'classnames'
import styles from './pagination.module.css'
import { Helmet } from "react-helmet";
import ReactGA from "react-ga4";
import { useAuthRouting, useSignedInUserState } from "../hooks/useAuthState";
import { restaurantService } from "../services/firebase/restaurantManagementService";
import { ProfileService } from "../services/profile/profile";

// A custom hook that builds on useLocation to parse
// the query string for you.
function useQuery() {
  const { search } = useLocation();
  return React.useMemo(() => new URLSearchParams(search), [search]);
}

function ListingSearchPage() {

  useAuthRouting()

  ReactGA.send({ hitType: "pageview", page: "/search", title: "Casa Grab" });
  let user = useSignedInUserState()
  let query = useQuery();
  var searchQuery = SearchListingsQuery.transformFromQueryParams(query);
  const [pageNumber, setPageNumber] = useState(0);
  const [listings, setListings] = useState<DistanceSearchItem[]>([])
  const [pageCount, setPageCount] = useState<number>(0)
  const itemsPerPage = 20;
  searchQuery.currentPage = pageNumber;
  console.log(pageCount)


  useEffect(() => {
    (async () => {
      if (
        user &&
        user.profile &&
        user.profile.addressDetails &&
        user.profile.addressDetails.lat &&
        user.profile.addressDetails.lon
      ) {
        const listings = await restaurantService.searchRestaurantsByProximity(
          parseFloat(user.profile?.addressDetails.lat),
          parseFloat(user.profile?.addressDetails.lon))
        setListings(listings);
        setPageCount(Math.ceil(listings.length / itemsPerPage));
      } else {

        const listings = await restaurantService.searchRestaurantsByProximity(0, 0)
        setListings(listings);
        setPageCount(Math.ceil(listings.length / itemsPerPage));
      }
    })();

  }, []);


  // Invoke when user click to request another page.
  const handlePageClick = (event: any) => {
    const newPageNumber = event.selected;
    console.log(
      `User requested page number ${newPageNumber}`
    );
    console.log(listings)
    setPageNumber(newPageNumber);
  };

  return (
    <div>

      <Helmet>
        <title>Good as new</title>
        <meta name="description" content="Good as new: Search restaurants near you" />
        {/* <!-- Open Graph Tags --> */}
        <meta property="og:title" content="Good as new - Food good as new" />
        <meta property="og:description" content="Food too good to go" />
        <meta property="og:image" content="./src_assets/659bf227c489a49f48e619d0_Test.png" />

        {/* <!-- Twitter Card Tags --> */}
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content="Good as new - Food good as new" />
        <meta name="twitter:description" content="Food too good to go" />
        <meta name="twitter:image" content="./src_assets/659bf227c489a49f48e619d0_Test.png" />

      </Helmet>
      <div className="p16 bgrTeal tcW">
        <Typography variant="h4" component="div" sx={{ color: 'white' }}>
          Restaurants with food too good to go.
        </Typography>
        <Typography variant="body1" component="div" sx={{ color: 'white' }}>
          Restaurants listed below are the closest to you<br />
          Search coming soon...
        </Typography>
      </div>
      <div className="ListingSearchPage">
        <div className="w20p" />
        <div className="ListingCardColumn">
          {
            listings.map(l => <ListingCard listing={l.restaurant} key={"listing-search-card-" + l.restaurant.id} />)
          }
        </div>
        <div className="w20p" />
      </div>

      <div className={classnames('issuesPagination', styles.pagination)}>
        <Paginate
          onPageChange={handlePageClick}
          pageCount={pageCount}
          marginPagesDisplayed={2}
          pageRangeDisplayed={20}
          forcePage={pageNumber}
          nextLabel="&rarr;"
          previousLabel="&larr;"
        />
      </div>
      <div className="section">
        <div className="container">
          <div className="footer-wrap">
          </div>
        </div>
      </div>
    </div>
  );
}

export default ListingSearchPage;
