import React, { useEffect, useState } from 'react';
import "./index.css"
import ImageGallery from "react-image-gallery";
import Card from '@mui/material/Card';
import "react-image-gallery/styles/css/image-gallery.css";
import { ImageService } from '../../services/images/images';
import { ImageGalleryType } from '../../models/imageGallery';


interface IProps {
  id: string
}


function ImageGalleryCard(props: IProps) {
  const [isFullScreen, setIsFullScreen] = useState(false);
  const [images, setImages] = useState<ImageGalleryType[]>([]); 

  useEffect(() => {
    const fetchImages = async () => {
      try {
      setImages(await ImageService.getGalleryImages(props.id)) 
      } catch (error) {
        console.log(error)
      }
    };

    fetchImages();
  }, [props.id]);

  return (
    <Card sx={{ backgroundColor: "#F0FFFF" }}>
      <ImageGallery items={images.map((x: any) => {
        if (!isFullScreen) {
          x.originalHeight = 300;
          x.originalWidth = 400;
        } else {
          x.originalHeight = null;
          x.originalWidth = null;  
        }
        return x;
      })} showThumbnails={false} showIndex={true} onScreenChange={setIsFullScreen} showBullets={true} />
    </Card>
    );
}

export default ImageGalleryCard;
