import { FirestoreService } from "./firestoreService";
import { MessageType } from "../../models/message";

const COLLECTION_NAME = "messages"

export const messageService = {
  sendMessageToUser: async (userId: string, newMessage: MessageType) => {
    await FirestoreService.addDocument(`${COLLECTION_NAME}/${userId}/userMessages`, newMessage); // Nested collection for user messages
  },

  updateMessage: async (userId: string, messageId: string, message: Partial<MessageType>) => {
    await FirestoreService.updateDocument(`${COLLECTION_NAME}/${userId}/userMessages`, messageId, message);
  },

  fetchMessagesByUser: async (userId: string): Promise<MessageType[]> => {
    try {
      const messages = (await FirestoreService.getCollection(`${COLLECTION_NAME}/${userId}/userMessages`)) as MessageType[];
      return messages.filter((msg: MessageType) => msg.isActive)
    } catch (error) {
      console.error("Error fetching messages:", error);
      throw new Error("Failed to fetch messages. Please try again later.");
    }
  },
};
