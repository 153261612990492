import React, { useState, useEffect } from "react";
import {
    Card,
    CardContent,
    Typography,
    Grid,
    Container,
    Box,
    List,
    ListItem,
    ListItemText,
} from "@mui/material";
import { NavLink, useNavigate } from "react-router-dom";
import { restaurantService } from "../services/firebase/restaurantManagementService";
import { useSignedInUserState } from "../hooks/useAuthState";
import AsyncCardMedia from "../shared/asyncCardMedia";
import { FullProperty } from "../models/fullProperty";
import { SaleItem } from "../models/saleItem";

const MaintainRestaurantsPage: React.FC = () => {
    const [restaurants, setRestaurants] = useState<FullProperty[]>([]);
    const [restaurantSaleItems, setRestaurantSaleItems] = useState<{
      [restaurantId: string]: SaleItem[];
    }>({});
    const [loading, setLoading] = useState<boolean>(true);
    const [error, setError] = useState<string>("");
    const navigate = useNavigate();
    const { user, profile } = useSignedInUserState();

    useEffect(() => {
        const fetchManagedRestaurants = async () => {
            try {
                console.log("user", user)
                console.log("profile", profile)
                setError("You are not signed in.");
                setLoading(false);
                if (!user || !user.uid || !profile) {
                    return;
                }
                setError("");
                setLoading(true);
                let data: FullProperty[] = [];
                if (profile.profileType === "staff") {
                    data = await restaurantService.fetchRestaurantsByStaff(user.uid);
                } else if (profile.profileType === "owner") {
                    data = await restaurantService.fetchRestaurantsByOwner(user.uid)
                } else {
                    navigate("/search")
                }
                setRestaurants(data);

                // Fetch sale items for each restaurant
        const saleItemsPromises = data.map(async (restaurant) => {
            const saleItems = await restaurantService.fetchSaleItems(restaurant.id);
            return { [restaurant.id]: saleItems };
          });
  
          const resolvedSaleItems = await Promise.all(saleItemsPromises);
          setRestaurantSaleItems(
            resolvedSaleItems.reduce(
              (acc, curr) => ({ ...acc, ...curr }),
              {}
            )
          );
            } catch (err) {
                setError("Failed to fetch managed restaurants.");
            } finally {
                setLoading(false);
            }
        };

        fetchManagedRestaurants();
    }, [user?.uid, profile?.profileType]);

    const handleNavigateToSpecific = (restaurantId: string) => {
        navigate(`/maintainRestaurant/${restaurantId}`);
    };

    return (
        <Box
          sx={{
            backgroundColor: "#f5f5f5",
            minHeight: "100vh",
            display: "flex",
            alignItems: "top",
            justifyContent: "center",
          }}
        >
          <Container>
            <Box textAlign="center" mt={4} mb={4}>
              <Typography variant="h4">Manage Your Restaurants</Typography>
            </Box>
            {error && <Typography color="error">{error}</Typography>}
            <div style={{ padding: "16px" }} />
            <Grid container spacing={3}>
              {restaurants.map((restaurant) => (
                <Grid item xs={12} sm={6} md={4} key={restaurant.id}>
                  <Card
                    sx={{ maxWidth: 345, margin: "auto", cursor: "pointer" }}
                    onClick={() => handleNavigateToSpecific(restaurant.id)}
                  >
                    <AsyncCardMedia
                      component="img"
                      height="140"
                      imageId={restaurant.id + "-thumbnail"}
                      alt="Restaurant Thumbnail"
                    />
                    <CardContent>
                      <Typography variant="h6">{restaurant.name}</Typography>
                      <Typography variant="body2" color="textSecondary">
                        Sale Items:
                      </Typography>
                      <List>
                        {restaurantSaleItems[restaurant.id]?.map((item) => (
                          <ListItem key={item.id}>
                            <ListItemText
                              primary={`ID: ${item.id}, Available: ${item.available}`}
                              secondary={`Dietary: ${item.dietryRequirements.join(
                                ", "
                              )}`}
                            />
                          </ListItem>
                        )) || (
                          <Typography variant="body2" color="textSecondary">
                            No sale items available.
                          </Typography>
                        )}
                      </List>
                    </CardContent>
                  </Card>
                </Grid>
              ))}
            </Grid>
          </Container>
        </Box>
      );
    };

export default MaintainRestaurantsPage;
