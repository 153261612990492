import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { AppBar, Toolbar, IconButton, Button, Typography, Box, Tooltip, List, ListItem, ListItemText, Divider } from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import SideDrawer from "./sideDrawer";
import { NavButton } from "../shared/navButton";
import { AuthButton } from "../shared/authButton";
import { StringsService } from "../services/strings/strings";
import ShoppingCartIcon from "@mui/icons-material/ShoppingCart";
import { auth } from "../firebase";
import { useCart } from "../hooks/contexts/cartContext";


// A custom hook that builds on useLocation to parse the query string for you.
function usePathname() {
  const { pathname } = useLocation();
  return React.useMemo(() => pathname, [pathname]);
}



function OneClickNavigation() {
  const pathname = usePathname();
  if (pathname === "/") {
    return <NavButton href="/search" text="View Properties" />;
  }
  if (pathname.includes("/search")) {
    return <NavButton href="/" text="Home" />;
  }
  if (pathname.includes("/listing")) {
    return <NavButton href="/search" text="View Properties" />;
  }
  return null;
}


function Header() {
  const [isDrawerOpen, setDrawerOpen] = useState(false);
  const [cartItemLabels, setCartItemLabels] = useState<{ [cartItemId: string]: string }>({})
  const [isLoading, setIsLoading] = useState(false)
  const { cart, fetchCart } = useCart(); // Access cart state
  const navigate = useNavigate()

  const toggleDrawer = (state: boolean) => setDrawerOpen(state);

  useEffect(() => {
    const getStrings = async () => {
      if (cart) {
        let newCartItemLabels: { [cartItemId: string]: string } = {}
        const promises = cart.items.map(async (element) => {
          const itemName = await StringsService.getStringById("restaurant-" + cart.restaurantId + "-saleItem-" + element.saleItem.id + "-sale-item-name");
          newCartItemLabels[element.saleItem.id] = `${itemName} ${element.quantity}x`
        });
        await Promise.all(promises)
        setCartItemLabels(newCartItemLabels)
      }
    }
    // Cleanup subscription on unmount
    getStrings();
  }, [cart]);

  useEffect(() => {
    fetchCart();
    console.log("running")
  }, [auth.currentUser]);

  return (
    <>
      <AppBar position="static" sx={{ backgroundColor: "#fff", color: "#000" }}>
        <Toolbar>
          <IconButton
            edge="start"
            color="inherit"
            aria-label="menu"
            onClick={() => toggleDrawer(true)}
          >
            <MenuIcon />
          </IconButton>
          <Typography
            variant="h6"
            component="a"
            href="/"
            sx={{
              flexGrow: 1,
              textDecoration: "none",
              color: "inherit",
            }}
          >
            <img
              src="/src_assets/659bf227c489a49f48e619d0_Test.png"
              alt="Casa Grab Logo"
              style={{ height: "40px", verticalAlign: "middle" }}
            />
          </Typography>

          <Tooltip
          onClick={() => {navigate("/order/confirm")}}
            title={
              cart && cart.items.length > 0 ? (
                <Box sx={{ p: 1 }}>
                  <Typography variant="subtitle1" gutterBottom>
                    Cart Items:
                  </Typography>
                  <List dense>
                    {cart.items.map((item) => (
                      <React.Fragment key={item.saleItem.id}>
                        <ListItem>
                          <ListItemText
                            primary={cartItemLabels[item.saleItem.id]}
                            secondary={
                              <Typography
                                variant="body2"
                                color="text.secondary"
                                sx={{ color: "lightgray" }} // Adjust the rgba value for the desired lightness
                              >
                                ${item.saleItem.price * item.quantity / 100}
                              </Typography>
                            }
                          />
                        </ListItem>
                        <Divider />
                      </React.Fragment>
                    ))}
                  </List>
                  <Typography variant="body2" align="right" sx={{ mt: 1 }}>
                    Total: R{cart.totalAmount / 100}
                  </Typography>
                </Box>
              ) : (
                "Your cart is empty"
              )
            }
            arrow
            placement="bottom"
          >
            <IconButton color="inherit">
              <ShoppingCartIcon />
              {cart && cart.totalAmount > 0 && (
                <Typography variant="body2" sx={{ ml: 1 }}>
                  R{cart.totalAmount / 100}
                </Typography>
              )}
            </IconButton>
          </Tooltip>

          <AuthButton />
          <OneClickNavigation />
        </Toolbar>
      </AppBar>
      <SideDrawer isOpen={isDrawerOpen} onClose={() => toggleDrawer(false)} />
    </>
  );
}

export { Header, OneClickNavigation };
