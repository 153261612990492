import { collection, doc, getDocs, addDoc, query, where, getFirestore, getDoc, updateDoc } from "firebase/firestore";
import { FullProperty } from "../../models/fullProperty";
import { DistanceSearchItem } from "../../models/search";
import { ProfileType } from "../../models/profileType";
import { ProfileService } from "../profile/profile";
import { firestore } from "../../firebase";
import { SaleItem } from "../../models/saleItem";

const RESTAURANT_COLLECTION = "restaurants"; // Replace with your Firestore collection name

export const restaurantService = {
    /**
     * Create a new restaurant in Firestore
     * @param restaurant - The restaurant data to be saved
     * @returns The Firestore document ID of the created restaurant
     */
    async createRestaurant(restaurant: FullProperty): Promise<string> {
        const docRef = await addDoc(collection(firestore, RESTAURANT_COLLECTION), {
            ...restaurant,
            location: {
                ...restaurant.location,
                latitude: parseFloat(restaurant.location.lat),
                longitude: parseFloat(restaurant.location.lon),
            },
        });
        // Update the document with its ID as the `location.id`
        await updateDoc(docRef, {
            "id": docRef.id,
        });
        return docRef.id;
    },

    async editRestaurant(id: string, restaurant: Partial<FullProperty>) {
        const docRef = doc(firestore, RESTAURANT_COLLECTION, id); // Reference to the specific document by ID
        await updateDoc(docRef, { ...restaurant });
    },

    async changeRestaurantActivityStatus(id: string, isActive: boolean) {
        if (!isActive) {
            await this.editRestaurant(id, {
                isActive,
                isPublished: false
            })
        }else {
            await this.editRestaurant(id, {
                isActive
            })
        }
    },

    /**
     * Fetch a specific restaurant by id
     * @param id - The restaurants id
     * @returns A FullProperty object
     */
    async fetchRestaurantById(id: string): Promise<FullProperty> {
        const docRef = doc(firestore, RESTAURANT_COLLECTION, id); // Reference to the specific document by ID
        const snapshot = await getDoc(docRef);

        if (!snapshot.exists()) {
            throw new Error(`Restaurant with ID ${id} not found.`);
        }

        const data = snapshot.data();
        return data as FullProperty; // Convert Firestore data to FullProperty instance
    },


    /**
     * Fetch all restaurants associated with a specific owner
     * @param ownerId - The owner's UID
     * @returns An array of FullProperty objects
     */
    async fetchRestaurantsByOwner(ownerId: string): Promise<FullProperty[]> {
        const q = query(collection(firestore, RESTAURANT_COLLECTION), where("owner", "==", ownerId));
        const snapshot = await getDocs(q);
        const restaurants: FullProperty[] = [];
        snapshot.forEach((doc) => {
            const data = doc.data();
            restaurants.push(data as FullProperty);
        });
        return restaurants;
    },

    /**
     * Fetch all restaurants associated with a specific staff member
     * @param ownerId - The owner's UID
     * @returns An array of FullProperty objects
     */
    async fetchRestaurantsByStaff(staffId: string): Promise<FullProperty[]> {
        const q = query(collection(firestore, RESTAURANT_COLLECTION), where("staff", "array-contains", staffId));
        const snapshot = await getDocs(q);
        const restaurants: FullProperty[] = [];
        snapshot.forEach((doc) => {
            const data = doc.data();
            restaurants.push(data as FullProperty);
        });
        return restaurants;
    },

    async fetchStaffByRestaurant(restaurant: FullProperty): Promise<ProfileType[]> {
        let profiles: ProfileType[] = []; 
        await Promise.all(restaurant.staff.map(async (uid) => {
            const profile = await ProfileService.getProfile(uid);
            if (profile) {
                profiles.push(profile);
            }
        }));
        return profiles;
    },

    /**
     * Search for restaurants sorted by proximity to a given location
     * @param latitude - The latitude of the customer's location
     * @param longitude - The longitude of the customer's location
     * @returns An array of FullProperty objects sorted by Euclidean distance
     */
    async searchRestaurantsByProximity(
        latitude: number,
        longitude: number
    ): Promise<DistanceSearchItem[]> {
        const snapshot = await getDocs(collection(firestore, RESTAURANT_COLLECTION));
        const restaurants: DistanceSearchItem[] = [];

        snapshot.forEach((doc) => {
            const data = doc.data() as FullProperty;
            const location = data.location;
            if (location && location.lat && location.lon && data.isActive && data.isPublished) {
                const distance = Math.sqrt(
                    Math.pow(parseFloat(location.lat) - latitude, 2) +
                    Math.pow(parseFloat(location.lon) - longitude, 2)
                );
                restaurants.push({
                    restaurant: data,
                    distance,
                });
            }
        });

        // Sort restaurants by distance
        return restaurants.sort((a, b) => (a as any).distance - (b as any).distance);
    },
    fetchSaleItems: async (restaurantId: string): Promise<SaleItem[]> => {
        const restaurant = await restaurantService.fetchRestaurantById(restaurantId);
        return restaurant.saleItems;
    },
    addSaleItem: async (restaurantId: string, saleItem: SaleItem): Promise<void> => {
        const restaurant = await restaurantService.fetchRestaurantById(restaurantId);
        await restaurantService.editRestaurant(restaurantId, {saleItems: [...restaurant.saleItems, saleItem]})
    },
    removeSaleItem: async (restaurantId: string, saleItem: SaleItem): Promise<SaleItem[]> => {
        const restaurant = await restaurantService.fetchRestaurantById(restaurantId);
        console.log(restaurant.saleItems)
        let newSaleItems: SaleItem[] = [];
        restaurant.saleItems.forEach((item) => { if(item.id !== saleItem.id) {
            newSaleItems.push(item);
        }})
        console.log(newSaleItems)
        await restaurantService.editRestaurant(restaurantId, { saleItems: newSaleItems })
        console.log(newSaleItems)
        return newSaleItems
    },
};
