// example query path
// http://localhost:3000/search?agentId=asdo&agencyId=asdo&areaId=asdo&priceMin=asdo&priceMax=asdo&houseSizeMin=asdo&houseSizeMax=asdo&plotSizeMin=asdo&plotSizeMax=asdo&roomCountMin=asdo&transactionalType=asdo&propertyType=asdo&isNewBuild=asdo&hasPool=asdo&isPetFriendly=asdo&hasGarden=asdo&hasFlatlet=asdo&isSecurityEstate=asdo&isRetiermentVillage=asdo&isOnAuction=asdo// ?agentId=asdo&agencyId=asdo&areaId=asdo&priceMin=asdo&priceMax=asdo&houseSizeMin=asdo&houseSizeMax=asdo&plotSizeMin=asdo&plotSizeMax=asdo&roomCountMin=asdo&transactionalType=asdo&propertyType=asdo&isNewBuild=asdo&hasPool=asdo&isPetFriendly=asdo&hasGarden=asdo&hasFlatlet=asdo&isSecurityEstate=asdo&isRetiermentVillage=asdo&isOnAuction=asdo

import { FullProperty } from "./fullProperty";
import { DietryRequirement } from "./saleItem";

export interface DistanceSearchItem {
    restaurant: FullProperty;
    distance: number;
}

class SearchListingsQuery {
    foodRestrictions: DietryRequirement[] = [];
    query: string = "";
    currentPage: number = 0;

    private static stringToDietryRequirementArray(str: string | null): Array<DietryRequirement> {
        if (str === null || str === "") {
            return ["no_requirements"]
        }
        var types = str.split(",").map(s => s as DietryRequirement);
        if ("no_requirements" in types) {
            return ["no_requirements"]
        }
        return types;
    }

    private static stringToBool(str: string | null, def: boolean): boolean {
        if (str === null && def !== null) {
            return def;
        }
        if (str === "true") {
            return true;
        }
        return false;
    }

    public static transformFromQueryParams(query: URLSearchParams): SearchListingsQuery {
        var searchQuery = new SearchListingsQuery();
        searchQuery.foodRestrictions = this.stringToDietryRequirementArray(query.get("foodRestrictions") ?? "");
        searchQuery.query = query.get("query") ?? "";
        searchQuery.currentPage = Number.parseFloat(query.get("currentPage") ?? "0")
    return searchQuery;
    }
}

export { SearchListingsQuery };