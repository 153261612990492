import { doc, setDoc, getDoc, updateDoc, deleteDoc, collection, getDocs, query, where, QueryFieldFilterConstraint, QuerySnapshot, DocumentData, addDoc } from "firebase/firestore";
import { firestore } from "../../firebase";

export const FirestoreService = {
  async addDocument(collectionName: string, data: object): Promise<void> {
    const colRef = collection(firestore, collectionName);
    await addDoc(colRef, data);
  },

  async getDocument(collectionName: string, docId: string): Promise<any> {
    const docRef = doc(firestore, collectionName, docId);
    const docSnap = await getDoc(docRef);
    return docSnap.exists() ? docSnap.data() : null;
  },

  async updateDocument(collectionName: string, docId: string, data: object): Promise<void> {
    const docRef = doc(firestore, collectionName, docId);
    await updateDoc(docRef, data);
  },

  async deleteDocument(collectionName: string, docId: string): Promise<void> {
    const docRef = doc(firestore, collectionName, docId);
    await deleteDoc(docRef);
  },

  async getCollection(collectionName: string): Promise<any[]> {
    const colRef = collection(firestore, collectionName);
    const snapshot = await getDocs(colRef);
    return snapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
  },

  async queryCollection(collectionName: string, filter:  QueryFieldFilterConstraint): Promise<QuerySnapshot<DocumentData, DocumentData>> {
    const colRef = collection(firestore, collectionName);
    const q = query(colRef, filter);
    return await getDocs(q)
  }
};
