import React from "react";
import {
    Card,
    CardContent,
    Typography,
    Box,
    Divider,
} from "@mui/material";
import { Nominatim } from "../models/nominatim";
import { StringsService } from "../services/strings/strings";
import AsyncString from "../shared/asyncString";

interface OSMResponseProps {
    addressDetails: Nominatim;
}

export const OSMDisplayCard: React.FC<OSMResponseProps> = ({
    addressDetails
}) => {

    if (!addressDetails) {
        return <div />
    }

    return (
        <Card sx={{ maxWidth: 500, margin: "auto", mt: 4, p: 2 }}>
            <CardContent>
                <Typography variant="h5" gutterBottom>
                    <AsyncString stringId={"location-details"} />
                </Typography>
                <Divider sx={{ mb: 2 }} />
                <Box sx={{ mb: 1 }}>
                    <Typography variant="subtitle1">
                        <AsyncString stringId={"latitude"} />:
                    </Typography>
                    <Typography variant="body2">{addressDetails.lat}</Typography>
                </Box>
                <Box sx={{ mb: 1 }}>
                    <Typography variant="subtitle1">
                        <AsyncString stringId={"longitude"} />:
                    </Typography>
                    <Typography variant="body2">{addressDetails.lon}</Typography>
                </Box>
                <Box sx={{ mb: 1 }}>
                    <Typography variant="subtitle1">
                        <AsyncString stringId={"display-name"} />:
                    </Typography>
                    <Typography variant="body2">{addressDetails.display_name}</Typography>
                </Box>
            </CardContent>
        </Card>
    );
};

