import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Typography from '@mui/material/Typography';
import { CardActionArea } from '@mui/material';
import { FullProperty } from '../../models/fullProperty';
import "./index.css"
import { StringsService } from '../../services/strings/strings';
import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';
import { useScreenSize } from '../../hooks/useScreenSize';
import AsyncCardMedia from '../../shared/asyncCardMedia';
import AsyncString from '../../shared/asyncString';

interface IProps {
    listing: FullProperty
}

function DesktopCard({ listing }: IProps) {
    var screenSize = useScreenSize()
    var cardImageWidth = 300;
    if (screenSize.width > 1000) {
        cardImageWidth += 0.2 * (screenSize.width - 1000);
    }
    return (
        <div className='ListingCardBase'>
            <CardActionArea >
                <Card sx={{ display: 'flex', width: "100%" }} >
                    <AsyncCardMedia
                        component="img"
                        sx={{ width: cardImageWidth }}
                        imageId={listing.id + "-thumbnail"}
                        alt="Live from space album cover"
                    />
                    <CardContent sx={{ display: 'flex', flexDirection: 'column', width: "100%", height: "100%" }}>
                        <div className="flex-row jcsb w100p">
                            <div className="flex-column">
                                <Typography sx={{ display: "inline" }} variant="h5" component="div">
                                    <AsyncString stringId={listing.id + "-restaurant-name"} />
                                </Typography>
                                <Typography variant="subtitle1" component="div">
                                    <AsyncString stringId={listing.id + "-restaurant-surprise-box-price-zar-displayable"} />
                                </Typography>
                                <Typography variant="subtitle1" component="div">
                                    <AsyncString stringId={listing.id + "-short-restaurant-summary"} />
                                </Typography>
                            </div>
                        </div>
                        <div className='h100p' />
                        {/* TODO: Update wit dietry requirements icons  */}
                        {/* <div className="flex-row ">
                            <div className='pr-16'>
                                <KingBedIcon />
                            </div>
                            <div className='pr-16'>
                                {listing.bedroomCount}
                            </div>
                            <div className='pr-16'>
                                <BathtubIcon />
                            </div>
                            <div className='pr-16'>
                                {listing.bathroomCount}
                            </div>
                            <div className='pr-16'>
                                <DirectionsCarIcon />
                            </div>
                            <div className='pr-16'>
                                {listing.carCount}
                            </div>
                            <div className='pr-16'>
                                <SquareFootIcon />
                            </div>
                            <div className='pr-16'>
                                {listing.houseSize}
                                {" "}
                                <AsyncString stringId={"meters_squared"} />
                            </div>
                        </div> */}
                    </CardContent>
                </Card>
            </CardActionArea>
        </div>
    );
}

function MobileCard({ listing }: IProps) {
    return (
        <div className='ListingCardBase'>
            <CardActionArea>
                <Card sx={{ display: 'flex', width: "100%", flexDirection: 'column' }}>

                    <AsyncCardMedia
                        component="img"
                        sx={{ height: 200 }}
                        imageId={listing.id + "-thumbnail"}
                        alt="Live from space album cover"
                    />
                    <CardContent>
                        <div className="flex-row jcsb">
                            <div className="flex-column">
                                <Typography sx={{ display: "inline" }} variant="h4" component="div">
                                    <AsyncString stringId={listing.id + "-restaurant-name"} />
                                </Typography>
                                <Typography variant="h6" sx={{ textDecoration: 'inline' }} component="div">
                                    <AsyncString stringId={listing.id + "-restaurant-surprise-box-price-zar-displayable"} />
                                </Typography>
                                <Typography variant="subtitle1" component="div">
                                    <AsyncString stringId={listing.id + "-short-restaurant-summary"} />
                                </Typography>
                            </div>
                        </div>
                        {/* TODO: Update wit dietry requirements icons  */}
                        {/* <div className="flex-row ">
                            <div className='pr-16'>
                                <KingBedIcon />
                            </div>
                            <div className='pr-16'>
                                {listing.bedroomCount}
                            </div>
                            <div className='pr-16'>
                                <BathtubIcon />
                            </div>
                            <div className='pr-16'>
                                {listing.bathroomCount}
                            </div>
                            <div className='pr-16'>
                                <DirectionsCarIcon />
                            </div>
                            <div className='pr-16'>
                                {listing.carCount}
                            </div>
                            <div className='pr-16'>
                                <SquareFootIcon />
                            </div>
                            <div className='pr-16'>
                                {listing.houseSize}
                                {" "}
                                <AsyncString stringId={"meters_squared"} />
                            </div>
                        </div> */}
                    </CardContent>
                </Card>
            </CardActionArea>
        </div>
    );
}

function ListingCard({ listing }: IProps) {
    var screenSize = useScreenSize()
    return <a href={"/listing/" + listing.id}>
        {
            screenSize.width > 800 ?
                < DesktopCard listing={listing} />
                :
                <MobileCard listing={listing} />
        }
    </a>
}

export { ListingCard };