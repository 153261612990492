import React, { useEffect, useState } from "react";
import {
  useParams
} from "react-router-dom";
import "./index.css"
import ImageGalleryCard from "./imageGalleryCard";
import { emptyProperty, FullProperty } from "../models/fullProperty";
import { useScreenSize } from "../hooks/useScreenSize";
import ShareCard from "./shareCard";
import { ListingSummaryCard } from "./summaryCard";
import DescriptionCard from "./longDescriptionCard";
import OverviewCard from "./overviewCard";
import { Helmet } from "react-helmet";
import { StringsService } from "../services/strings/strings";
import ReactGA from "react-ga4";
import { restaurantService } from "../services/firebase/restaurantManagementService";
import CartPillButton from "./cartPillButton";

interface IBodyProps {
  property: FullProperty
}

function DesktopListingBody(props: IBodyProps) {
  return (<div className="row jcsb">
    {/* main column */}
    <div className="col w100p">
      <ListingSummaryCard listing={props.property} />
      <div className="p8" />
      <DescriptionCard id={props.property.id} />
      <div className="p8" />
      <OverviewCard listing={props.property} />
      <CartPillButton />
    </div>
    <div className="p8" />
    {/* side column */}
    <div className="col minW250">
      <ShareCard id={props.property.id} />
    </div>
  </div>)
}

function MobileCard(props: IBodyProps) {
  return (
    <div className="col w100p">
      <ListingSummaryCard listing={props.property} />
      <div className="p8" />
      <DescriptionCard id={props.property.id} />
      <div className="p8" />
      <OverviewCard listing={props.property} />
      <div className="p8" />
      <ShareCard id={props.property.id} />
      <CartPillButton />
    </div>
  )
}

function ListingBody(props: IBodyProps) {
  var screenSize = useScreenSize()
  if (screenSize.width > 800) {
    return <DesktopListingBody property={props.property} />
  }
   return  <MobileCard property={props.property} />
}

function ListingDetailsPage() {
  const { id } = useParams();

  const [restaurantName, setRestaurantName] = useState<string>("");
  const [shortSummary, setShortSummary] = useState<string>("");
  const [thumbnail, setThumbnail] = useState<string>("");
  const [listing, SetListing] = useState<FullProperty>(emptyProperty);

  useEffect(() => {
    const fetchStrings = async () => {
      const name = await StringsService.getStringById(`${id}-restaurant-name`);
      const summary = await StringsService.getStringById(`${id}-short-restaurant-summary`);
      const image = await StringsService.getStringById(`${id}-thumbnail`);
      setRestaurantName(name);
      setShortSummary(summary);
      setThumbnail(image);
      SetListing(await restaurantService.fetchRestaurantById(id ?? ""))
    };

    fetchStrings();
  }, [id]);

  
  ReactGA.send({ hitType: "pageview", page: "/listing/" + id, title: "Casa Grab" });
  
  return (
    <div >
    <Helmet>
      <title>{`Too Good - ${restaurantName}`}</title>
      <meta
        name="description"
        content={`Good To Go, Steers... ${shortSummary}`}
      />
      {/* Open Graph Tags */}
      <meta property="og:title" content={`Too Good - ${restaurantName}`} />
      <meta
        property="og:description"
        content={`Good To Go, Steers... ${shortSummary}`}
      />
      <meta property="og:image" content={thumbnail} />

      {/* Twitter Card Tags */}
      <meta name="twitter:card" content="summary_large_image" />
      <meta name="twitter:title" content={`Too Good - ${restaurantName}`} />
      <meta
        name="twitter:description"
        content={`Good To Go, Steers... ${shortSummary}`}
      />
      <meta name="twitter:image" content={thumbnail} />
    </Helmet>
      <div className="p8" />
      <div className="ListingDetailPage">
        <ImageGalleryCard id={id ?? ""} />
        <div className="p8" />
        <ListingBody property={listing} />
      </div>
      <div className="p8" />
    </div>
  );
}

export default ListingDetailsPage;
