import { onAuthStateChanged, User } from "firebase/auth";
import { useState, useEffect } from 'react';
import { auth } from "../firebase";
import { useNavigate } from "react-router-dom";
import { ProfileType } from "../models/profileType";
import { ProfileService } from "../services/profile/profile";

export const useAuthRouting = () => {
  const navigate = useNavigate();
  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      if (user) {
        console.log("User is already signed in:", user);
      } else {
        navigate("/login");
      }
    });

    // Cleanup subscription on unmount
    return () => unsubscribe();
  }, [navigate]);
};

export const useSignedInState = () => {
  const [signedIn, setSignedIn] = useState(true);
  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      if (user) {
        console.log("User is already signed in:", user);
        setSignedIn(true)
      } else {
        setSignedIn(false)
      }
    });

    // Cleanup subscription on unmount
    return () => unsubscribe();
  }, []);
  return signedIn;
};

export interface signedInUser {
  user: User | null;
  profile: ProfileType | null;
}

export const useSignedInUserState = (): signedInUser => {
  const [user, setUser] = useState<User | null>(null);
  const [profile, setProfile] = useState<ProfileType | null>(null);
  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, async (user) => {
      if (user) {
        console.log("User is already signed in:", user);
        setUser(user);
        const profile = await ProfileService.getProfile(user.uid)
        setProfile(profile);
      } else {
        setUser(null)
      }
    });

    // Cleanup subscription on unmount
    return () => unsubscribe();
  }, [user]);
  return {user, profile};
};